import React from "react";

export class TermsLayout extends React.Component {
  state = {
  }


  render() {
    return (
      <>
        {/* {this.state.isCompanyAvi ?
          this.props.children : ''
        } */}
        <div className="mt-5 pt-3">
          {this.props.children}
        </div>
      </>
    )
  }
}

