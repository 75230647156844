import React, { useState, useEffect } from 'react';
import "./style.scss";
import { CommonPageLoader } from 'component/common';
import No_results_found from "assets/svg/no_data_found_illus.svg";
import { getNotifications } from "../../../action/notification";
import { bindActionCreators } from "redux";
import { connect, useSelector } from "react-redux";
import moment from "moment";
import notifi_clock from "assets/svg/noti_clock.svg";
import refreshIcon from "../../../assets/svg/mdi_refresh_white.svg";
import closeIcon from "../../../assets/svg/cross.svg";
import singleTransparent from 'assets/svg/single transparent.svg';
import jobCategoryTransparent from 'assets/svg/job_category_white.svg';
import pricetagTransparent from 'assets/svg/price-tag transparent.svg';
import clientTransparent from 'assets/svg/Client_transparent.svg';
import customerTransparent from 'assets/svg/customer transparent.svg';
import settingsTransparent from 'assets/svg/settings transparent.svg';
import { useHistory } from 'react-router-dom';

function NotificationSettings(props) {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);

     const history = useHistory()
    let notificationCount = useSelector((state)=> state.webSocketReducer.notificationCount)
    useEffect(() => {
        if(props.sideBarClickStatus)
        fetchAll();
    }, [props.sideBarClickStatus]);
    useEffect(() => {
        if(props.sideBarClickStatus && notificationCount > 0)
        fetchAll();
    }, [notificationCount]);

    const fetchAll = async () => {
        setLoading(true);
        const query = {
        };
        await props.getNotifications(query).then((data) => {
            setData(data);
            setLoading(false);

        }).catch((e) => {
            setLoading(false);
        });
    };
    return (
        <>
            {/* <Title label='Notification' /> */}
            <div className='d-flex modal-headerNotification'>
                <div className='col-10 '>
                    <h5 className="position-fixed mt-2">Notification</h5>
                </div>
                <div className='col-2 d-flex justify-content-end'>
                <img className='refreshIconInModel mt-2' src={refreshIcon} onClick={fetchAll} alt='refresh icon'/>
                <img className='refreshIconInModel ml-2 mt-2'  src={closeIcon} onClick={props.handleSideBar} alt='close icon'/>
                </div>
            </div>
            {!loading ? (
                <>
                 <div className="down">
                    {
                    data.length ?
                    data.sort((a,b)=>{return moment(b.date) - moment(a.date);}).map(({date, notifications}, index) => {
                        return (
                            <>
                                <div className="ml-3 mr-3" key={index}>
                                    <h6 className="font-weight-bold">{moment(date).format("MM/DD/YYYY")}</h6>
                                    {notifications.map((item) =>
                                        <div className="container notification-bar">

                                            <div className="row align-items-start p-3 align-items-stretch">
                                                {/* <div className="col-1 d-flex justify-content-start">
                                                    </div> */}
                                                <div className="col-9 pl-0 d-flex justify-content-start align-items-center" style={{paddingRight:"0"}}>
                                                    <div className="checkBox col-1">
                                                    <img className='notif-icon' style={{width: "20px"}} src = {
                                                        item.path.split('/')[1] === "userManagement" ? singleTransparent : 
                                                        item.path.split('/')[1] === "getAllJobCategories" ?jobCategoryTransparent : 
                                                        item.path.split('/')[1] === "subscription" ? pricetagTransparent : 
                                                        item.path.split('/')[1] === "agencies" ? customerTransparent : 
                                                        item.path.split('/')[1] === "client" ? clientTransparent:
                                                        item.path.split('/')[1] === "jobMaster" ? jobCategoryTransparent : 
                                                        item.path.split('/')[1] === "settings" ? settingsTransparent :
                                                        ""
                                                    } alt="" />
                                                    </div>
                                                    <div className="col-10" style={{paddingRight:"0"}}>
                                                        {/* <p className=" pl-3 fs-14 font-weight-bold mb-1 wordwrap text-grey-42">{item.status}</p> */}
                                                        <p className="fs-12 pl-3 wordwrap fixed-height mb-0 text-grey-6c">
                                                            {item.message}
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="col-2 text-right pr-4 d-flex flex-column justify-content-between cursor-pointer text-center" onClick={()=>{
                                                    if(item.path.split('/')[1] === 'jobMaster' && item.path.split('/')[1]){
                                                        history.push(`/admin/jobCategories?view=true&jobMasterId=${item.path.split('/')[2]}`)
                                                        return;
                                                    }
                                                        history.push(item.path?`/admin`+item.path:'')
                                                    
                                                }}>
                                                    <div>
                                                    view
                                                    </div>
                                                    <div class="frame-4430">
                                                            <img class="notifi_clock" src={notifi_clock} />
                                                            <div class="_notifi-time">{moment(item.createdAt).format('hh:mm a')}</div>
                                                     </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                    }
                                </div>


                            </>
                        );
                    })
                    :
                    <img
                    className="preload-image no_result_found"
                    src={No_results_found}
                    onLoad={() => {
                      document.querySelector('.preload-image').style.display = 'block';
                    }}
                    alt="No Results Found"
                  />
                }
                </div>
                </>
            ) :
                <div style={{marginLeft:"6rem"}} colSpan="6" className="border-0">
                    <CommonPageLoader />
                </div>
            }

        </>
    )
}


const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            getNotifications
        },
        dispatch
    );
};

export default connect(mapDispatchToProps, mapDispatchToProps)(NotificationSettings);