import { notification } from "../service/apiVariables";
import { addQuery } from "service/helperFunctions";

export const getNotifications = (query) => (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
        addQuery(query, notification.get);
        api({ ...notification.get })
            .then(({ data }) => {
                resolve(data);
            })
            .catch(({ message }) => {
                reject(Toast({ type: "error", message }));
            });
    });
};
export const getNotificationsCout = (query) => (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
        addQuery(query, notification.count);
        api({ ...notification.count })
            .then(({ data }) => {
                resolve(data);
            })
            .catch(({ message }) => {
                reject(
                    // Toast({ type: "error", message })
                    );
            });
    });
};