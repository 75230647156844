import React, { useEffect, useState } from 'react'
import './style.scss'
import loadingIcon from "assets/images/loadingImage.png";
import agencyLogo from "assets/svg/dummyFrame 2497.svg";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import nav_user from "../../../assets/svg/user.svg"
import { getFileFromMDB } from 'action/fileHandler';

const DisplayImage = React.memo(({getFileFromMDB, imageId, entity, agencyId, clientId, onSuccess, rounded, navbar,navprofile}) => {
  const [onlineImage, setOnlineImage] = useState('')
  const [loading, setLoading] = useState(false)

      const handleImageDownload = async (logo) => {
        setLoading(true)
        let imageData;
        let fileId = logo.split('/')[1]
        await getFileFromMDB({entity:entity?entity:'', agencyId: agencyId ? agencyId : '', clientId: clientId ? clientId : ''},fileId)
          .then((response)=>{
            imageData = `data:${response.contentType};base64,${response?.fileContent.data}`
            setLoading(false)
            setOnlineImage(imageData.toString())
            if(onSuccess){
              onSuccess()
            }
            return imageData
          })
          .catch((error) => {
            setLoading(false);
            setOnlineImage(null);
            console.log('Error fetching image:', error);
          });
      };

      useEffect(()=>{
        if(imageId){
          handleImageDownload(imageId)
        }else{
          setOnlineImage(null)
        }
      },[imageId])

  return (<>
    {onlineImage !== null ?
      <img className={`${navbar && 'profile-nav rounded-circle'} ${rounded && 'profile-icon rounded-circle'} ${loading ? 'loading-animation': 'image-view'}`}
         src={loading ? loadingIcon : onlineImage} /> 
           : !navprofile ? (
            <div style={{width:"20%"}} className="h-100 d-flex justify-content-center align-items-center">
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip>Image not found</Tooltip>}
              >
                <div className="w-100 h-100 d-flex justify-content-center align-items-center">
                  <img style={{ height: "30px", width: "30px" }} src={agencyLogo} alt="Dummy Profile" />
                </div>
            </OverlayTrigger>
            </div>
          ) : <img
            style={{width: "30px",
            height: "30px",
            marginBottom: "1rem"
            }} src={nav_user}/> }
</>)
})

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
      {
        getFileFromMDB
      },
      dispatch
    );
  };

  export default connect('', mapDispatchToProps)(DisplayImage)

