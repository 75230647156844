import { addTaxRegion, getAccountingCodes, updateTaxRegion } from "action/TaxSettingsAct";
import { generateQuery } from "./helperFunctions";

export const authApi = {
  adminLogin: {
    api: "v1/login",
    method: "post",
    baseURL: "auth",
  },
  adminLogOut: {
    url: 'v1/logOut',
    method: 'put',
    baseURL: 'auth',
    query: {
      auditId:'',
    },
    get api() {
       return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
       this.query[key] = payload;
    },
 },
  otpLogin: {
    api: "v1/otpLogin",
    method: "post",
    baseURL: "auth",
  },
  forgetPassword: {
    url: "v1/sendOtp",
    method: "put",
    baseURL: "auth",
    query: {
      emailId: null,
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  resetPassword: {
    api: "v1/resetPassword",
    method: "post",
    baseURL: "auth",
  },
  updatePassword: {
    api: "v1/resetPassword",
    method: "post",
    baseURL: "auth",
  },
  OTPVerify: {
    url: "v1/otpVerify",
    method: "put",
    baseURL: "auth",
    query: {
      emailId: null,
      otp: null,
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    }
  },
  changePassword: {
    url: "v1/sendOtp",
    method: "put",
    baseURL: "auth",
    query: {
      emailId: null,
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  sendOtpToNewEmail: {
    url: "migrate/sendOtp",
    method: "put",
    baseURL: "auth",
    query: {
      emailId: null,
      newEmailId: null,
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    }
  },
  otpVerifyNewEmail: {
    url: "migrate/otpVerify",
    method: "put",
    baseURL: "auth",
    query: {
      emailId: null,
      newEmailId: null,
      otp: null
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    }
  }
};

export const subscriptionApi = {
  postAdd: {
    api: "subscription/add",
    method: "post",
    baseURL: "subscription",
  },
  getCurrency: {
    api: "subscription/getAllCurrency",
    method: "get",
    baseURL: "subscription",
  },
  getCountry: {
    api: "subscription/getAllCountry",
    method: "get",
    baseURL: "subscription",
  },

  getUsers: {
    url: "subscription/getAll",
    method: "get",
    baseURL: "subscription",
    query: {
      filter: null,
      region: null,
      page: null,
      size: null,
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  getRegion: {
    url: "subscription/getAllRegion",
    method: "get",
    baseURL: "subscription",
    query: {
      filter: null,
      region: null,
      page: null,
      size: null,
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  getPlans: {
    url: "subscription/getAllSubscription",
    method: "get",
    baseURL: "subscription",
    query: {
      filter: null,
      region: null,
      page: null,
      size: null,
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },

  search: {
    url: "subscription/getByName",
    method: "get",
    baseURL: "subscription",
    query: {
      region:null,
      filter:null,
      name: null,
      page: null,
      size: null,
      status: null,
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  getUsersById: {
    url: "subscription/getById",
    method: "get",
    baseURL: "subscription",
    query: {
      id: null,
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  editUserUpdate: {
    api: "subscription/update",
    method: "put",
    baseURL: "subscription",
  },
  putSaveDraft: {
    url: "subscription/update",
    method: "put",
    baseURL: "subscription",
    query: {
      id: null,
      status: null,
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  deleteUserlist: {
    url: "subscription/delete",
    method: "delete",
    baseURL: "subscription",
    query: {
      id: null,
      deleteFlag: null,
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  paymentTransactions: {
    url: "payment/getAllTransactions",
    method: "get",
    baseURL: "subscription",
    query: {
      startDate:'',
      endDate:'',
      paymentStatus:'',
      userType: null,
      page: '',
      size: ''
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  gettransactionLogsCSV: {
    url: "payment/transactionLogsCSV",
    method: "get",
    baseURL: "subscription",
    query: {
      userType: null
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  }
};
export const discountApi = {
  postAdd: {
    api: "discount/add",
    method: "post",
    baseURL: "discount",
  },
  getAll: {
    url: "discount/getAll",
    method: "get",
    baseURL: "discount",
    query: {
      filter: null,
      region: null,
      page: null,
      size: null,
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  getById: {
    url: "discount/getById",
    method: "get",
    baseURL: "discount",
    query: {
      id: null,
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  search: {
    url: "discount/getByName",
    method: "get",
    baseURL: "discount",
    query: {
      name: null,
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  getByCode: {
    url: "discount/getByCode",
    method: "get",
    baseURL: "discount",
    query: {
      code: null,
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  deleteById: {
    url: "discount/delete",
    method: "put",
    baseURL: "discount",
    query: {
      id: null,
      status: null,
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
};
export const userManagementApi = {
  getAllUsers: {
    url: "getUserList",
    method: "get",
    baseURL: "User",
    query: {
      status: "",
      page: 1,
      size: null,
      search: null,
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  search: {
    url: "searchByName",
    method: "get",
    baseURL: "User",
    query: {
      name: null,
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },

  addCoAdmin: {
    api: "createUser",
    method: "post",
    baseURL: "User",
  },

  updateCoAdmin: {
    api: "updateUser",
    method: "put",
    baseURL: "User",
  },

  getUserById: {
    url: "getUserById",
    method: "get",
    baseURL: "User",
    id: 1,
    get api() {
      return this.url + "?userId=" + this.id;
    },
    set addQuery({ key, payload }) {
      this[key] = payload;
    },
  },

  archiveUser: {
    api: "archiveUser",
    method: "put",
    baseURL: "User",
  },

  enablePermission: {
    api: "enablePermission",
    method: "put",
    baseURL: "User",
  },

  activeStatus: {
    api: "activeStatus",
    method: "put",
    baseURL: "User",
  },

  updateUser: {
    api: "updateUser",
    method: "put",
    baseURL: "User",
  },
};
export const jobCategoriesApi = {
  getAllJobCategories: {
    url: "v1/getAllJobCategories",
    method: "get",
    baseURL: "job",
    query: {
      searchKey:'',
      status:'',
      category:'',
      jobFunctionStatus:'',
      page:1,
      size:10
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  getJobCategoryById: {
    url: "v1/getJobCategoryById",
    method: "get",
    baseURL: "job",
    query: {
      id:'',
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  activateJobFunction: {
    url: "v1/activateJobFunction",
    method: "put",
    baseURL: "job",
    query: {
      id:'',
      name:''
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },

  deactivateJobFunctionById: {
    url: "v1/deactivateJobFunctionById",
    method: "put",
    baseURL: "job",
    query: {
      functionId:"",
      status:""
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  
  addJobCategory: {
    url: "v1/addJobCategory",
    method: "post",
    baseURL: "job",
    get api() {
      return this.url;
    },
    
  },
  updateJobCategory: {
    url: "v1/updateJobCategory",
    method: "put",
    baseURL: "job",
    get api() {
      return this.url;
    },
  },
  deleteJobCategory: {
    url: "v1/deleteJobCategoryById",
    method: "put",
    baseURL: "job",
    query: {
      id:''
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  addJobFunctionById: {
    url: "v1/addJobFunctionById",
    method: "post",
    baseURL: "job",
    query: {
      id:'',
      name:'',
      purpose:'',
      searchKey:[],
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  deleteJobFunctionById: {
    url: "v1/deleteJobFunctionById",
    method: "put",
    baseURL: "job",
    query: {
      functionId:'',
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },

  jobFunctionNotifyUsers: {
    url: "v1/notifyUsers",
    method: "post",
    baseURL: "job",
    query: {
      category:'',
      functionName:'',
      jobMasterId:'',
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
}

export const clientManagementApi = {
  getAllClients : {
    url: "client/getAll",
    method: "get",
    baseURL: "client",
    query: {
      page: 1,
      size: 10,
      status: null,
      search: null,
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  getClientById: {
    url: "client/getProfile",
    method: "get",
    baseURL: "client",
    query: {
      id: null,
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  searchByClientName:{
    url: "client/searchByName",
    method: "get",
    baseURL: "client",
    query: {
      name: null,
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  updateClient : 
    {
      api: "client/updateProfile",
      method: "put",
      baseURL: "client",
    },

   deleteClientData:{
      url: "deleteAccountData",
      method: "delete",
      baseURL: "User",
      query: {
        userId: null,
        userType: null,
      },
      get api() {
        return this.url + generateQuery(this.query);
      },
      set addQuery({ key, payload }) {
        this.query[key] = payload;
      },
    }

    
}
export const agencyManagementApi = {
  getAllAgencies: {
    url: "getAll",
    method: "get",
    baseURL: "Agency",
    query: {
      filter: '',
      page: 1,
      size: 10,
      search: null
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  search: {
    url: "agencyDetailsByName",
    method: "get",
    baseURL: "Agency",
    query: {
      agencyName: null,
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  inviteAgency: {
    api: "inviteAgency",
    method: "post",
    baseURL: "Agency",
  },

  addAgency: {
    api: "add",
    method: "post",
    baseURL: "Agency",
    type: "basic",
  },

  getTimeZoneList: {
    url: "subscription/getTimeZoneList",
    method: "get",
    baseURL: "subscription",
    query: {
       timezone: ["America", "Asia", "US", "Pacific"],
    },
    get api() {
       return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
       this.query[key] = payload;
    },
 },
  updateAgency: {
    url: "updateAgency",
    method: "put",
    baseURL: "Agency",
    get api() {
      return this.url + "?id=" + this.id;
    },
    set addQuery({ key, payload }) {
      this[key] = payload;
    },
  },

  changePlan: {
    url: "updateSubscriptionPlan",
    method: "put",
    baseURL: "Agency",
    id: 1,
    get api() {
      return this.url + "?id=" + this.id;
    },
    set addQuery({ key, payload }) {
      this[key] = payload;
    },
  },

  getAgencyById: {
    url: "getSingleAgency",
    method: "get",
    baseURL: "Agency",
    id: 1,
    get api() {
      return this.url + "?id=" + this.id;
    },
    set addQuery({ key, payload }) {
      this[key] = payload;
    },
  },
  activeStatus: {
    url: "updateStatus",
    method: "put",
    baseURL: "Agency",
    query: {
      id: null,
      status: null,
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  deleteAgency: {
    url: "updateDeleteFlag",
    method: "delete",
    baseURL: "Agency",
    query: {
      id: null,
      deleteFlag: null,
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  }, 
  deleteAgencyData:{
    url: "deleteAccountData",
    method: "delete",
    baseURL: "User",
    query: {
      userId: null,
      userType: null,
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  }
};

export const contentMaganementApi = {
  getAllAgencies: {
    url: "content/news/news/get",
    method: "get",
    baseURL: "Content",
    query: {
      limit: 10,
      page: 1,
      size: null,
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },

  addNews: {
    api: "content/news/news/add",
    method: "post",
    baseURL: "Content",
  },
  updateNews: {
    api: "content/news/news/update",
    method: "put",
    baseURL: "Content",
  },
  getAllNews: {
    url: "content/news/news/get",
    method: "get",
    baseURL: "Content",
    query: {
      limit: 10,
      page: 1,
      size: null,
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  getNewsById: {
    url: "content/news/news",
    method: "get",
    baseURL: "Content",
    id: 1,
    get api() {
      return this.url + "/" + this.id;
    },
    set addQuery({ key, payload }) {
      this[key] = payload;
    },
  },
  deleteNewsById: {
    url: "content/news/delete",
    method: "delete",
    baseURL: "Content",
    id: 1,
    get api() {
      return this.url + "/" + this.id;
    },
    set addQuery({ key, payload }) {
      this[key] = payload;
    },
  },
};

export const reportLogs = {
  getAllLogs: {
    url: "getLogs",
    method: "get",
    baseURL: "Logs",
    query: {
      email: null,
      userType: null,
      page: null,
      filter: null,
      size: null,
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  getReport: {
    url: "report/getReport",
    method: "get",
    baseURL: "report",
    query: {
      page: null,
      size: null,
      sort: null,
      planName: null,
      startDate: null,
      endDate: null,
      region: null,
      agencyName: null,
      status: null
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  getReportHeader: {
    url: "report/header",
    method: "get",
    baseURL: "report",
    query: {},
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  searchAudit: {
    url: "getLogs",
    method: "get",
    baseURL: "Logs",
    query: {
      page: 1,
      filter: "all",
      size: 10,
      searchKey: null,
      userType: null,
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  searchReport: {
    url: "report/agencyDetailsByName",
    method: "get",
    baseURL: "report",
    query: {
      agencyName: null,
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  reportCsv: {
    api: "report/agencyCSV",
    method: "get",
    baseURL: "report",
  },
  logsCsv: {
    api: "v1/logsCSV",
    method: "get",
    baseURL: "logCsv",
  },
  getLoginLogs: {
    url: "v1/getAllLoginSessionLogs",
    method: "get",
    baseURL: "report",
    query: {
      email: null,
      page: null,
      size: null,
      status:null,
      userType:null
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  getloginSessionLogsCSV: {
    api: "v1/loginSessionLogsCSV",
    method: "get",
    baseURL: "logCsv",
  }
};

export const dashboard = {
  getSubscriber: {
    url: "dashboard/subscriber",
    method: "get",
    baseURL: "dashboard",
    query: {},
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  getValues: {
    url: "dashboard/header",
    method: "get",
    baseURL: "dashboard",
    query: {},
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  getUsers: {
    url: "dashboard/users",
    method: "get",
    baseURL: "dashboard",
    query: {
      sort: null,
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  getActivity: {
    url: "dashboard/activity",
    method: "get",
    baseURL: "dashboard",
    query: {
      startDate: null,
      endDate: null,
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  getLatlong: {
    url: "dashboard/longlat",
    method: "get",
    baseURL: "dashboard",
    query: {},
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  userCSV: {
    api: "dashboard/usersCSV",
    method: "get",
    baseURL: "dashboard",
  },
  subscriberCSV: {
    api: "dashboard/subscriberCSV",
    method: "get",
    baseURL: "dashboard",
  },
};

export const documentUpload = {
  create: {
    api: "v1/storage/aws/uploadFile",
    method: "post",
    baseURL: "fileUpload",
  },
};

export const documentUploadOnMDB = {
  create: {
     api: 'v1/storage/mdb/uploadFile',
     method: 'post',
     baseURL: 'fileUpload',
  },
};

export const documentDownloadFromMDB = {
  create: {
     api: 'v1/storage/mdb/download/file/',
     method: 'get',
     baseURL: 'fileUpload',
     query: {
        'id': null,
     },
  },
};

export const notification = {
  get: {
    url: "notification/get",
    method: "get",
    baseURL: "notification",
    query: {},
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  count: {
    url: "notification/notificationCount",
    method: "get",
    baseURL: "notification",
    query: {},
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
};

export const fileUploadOnMDB = {
  create: {
     url: 'v1/storage/mdb/uploadFile',
     method: 'post',
     baseURL: 'fileUpload',
     query: {
      entity:'admin',
      agencyId:null,
     },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
};

export const fileDownloadFromMDB = {
  getFile: {
     url: 'v1/storage/mdb/download/file',
     method: 'get',
     baseURL: 'fileUpload',
     query: {
        fileId:'',
        entity:'admin',
        agencyId: '',
        clientId:'',
     },
     get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  deleteFile: {
    url: 'v1/storage/mdb/deleteFile',
    method: 'delete',
    baseURL: 'fileUpload',
    query: {
       fileId:'',
       agencyId: '',
       entity:'',
    },
    get api() {
     return this.url + generateQuery(this.query);
   },
   set addQuery({ key, payload }) {
     this.query[key] = payload;
   },
 },}; 

export const accessControlApi = { 
  getAllApiEndPoints : 
    {
      url: "getAllApiEndpoints",
      method: "get",
      baseURL: "User",
      query: {
        page:null,
        Search:null,
        size:null
      },
      get api() {
        return this.url + generateQuery(this.query);
      },
      set addQuery({ key, payload }) {
        this.query[key] = payload;
      },
  }, 

  getApiEndPoint : 
  {
    url: "getApiEndpoint",
    method: "get",
    baseURL: "User",
    query: {
      endpoint:null,
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  }, 
  addApiEndPoint:{
    api: "addApiAccessControl",
    method: "post",
    baseURL: "User",

  },
  updateApiEndPoint:{
    api: "updateApiAccessControl",
    method: "put",
    baseURL: "User",

  },
  
  deleteApiEndPoint:{
    url: "deleteApiAccessControl",
    method: "delete",
    baseURL: "User",
    query: {
      endpoint:null,
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  } ,
  exportApiAccessControlAsCSV : {
    api: "exportApiAccessControlAsCsv",
      method: "get",
      baseURL: "User",
  }
,
  exportApiAccessControlAsJSON:{
      api: "exportApiAccessControlAsJson",
      method: "get",
      baseURL: "User",
  }


}

export const taxSettingsApi = {
  getTaxRegion: {
    url: "tax/region",
    method: "get",
    baseURL: "job",
    query: {
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },

  addTaxRegion: {
    url: "tax/region",
    method: "post",
    baseURL: "job",
    query: {
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },

  updateTaxRegion: {
    url: "tax/region",
    method: "put",
    baseURL: "job",
    query: {
      code: "",
      id: "",
      country: "",
      currency: "",
      symbol: "",
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },

  deleteTaxRegion: {
    url: "tax/region",
    method: "delete",
    baseURL: "job",
    query: {
      regionId: ""
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },

  getAccountingCodes: {
    url: "tax/accountingCode",
    method: "get",
    baseURL: "job",
    query: {
      region: ""
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },

  updateAccountingCode: {
    url: "tax/accountingCode",
    method: "put",
    baseURL: "job",
    query: {
      
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },

  updateState: {
    url: "tax/state",
    method: "put",
    baseURL: "job",
    query: {
      
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },

  importAccountingCodes: {
    url: "import/accountingCode",
    method: "post",
    baseURL: "fileUpload",
    query: {
      regionId: ""
    },
    responseType:'',
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },

  importPlaceOfSupply: {
    url: "import/states",
    method: "post",
    baseURL: "fileUpload",
    query: {
      regionId: ""
    },
    responseType:'',
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  }
}