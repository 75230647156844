import React, { Component } from 'react';
import "./style.scss";
export class NormalTextarea extends Component {

    render() {
        let {
            className = 'form-control',
            placeholder = '',
            onChange,
            value = '',
            name,
            disabled = false,
            resize,
            rows = '4',
            maxlength,
            label = ''
        } = this.props;

        return (
            <div className="normal-text-area-input">
                {label !== '' ?
                    <div className="fs-17 text-black font-JosefinSans pb-2 mb-1 line-height-20">
                        {label}
                    </div>
                    : ''}
                <textarea
                    maxLength={maxlength}
                    rows={rows}
                    className={`${className} ${!resize ? 'resize-none' : ''}`}
                    name={name}
                    disabled={disabled}
                    value={value}
                    placeholder={placeholder}
                    onChange={e => {
                        let body = {};

                        body = {
                            target: {
                                name: e.target.name,
                                value: e.target.value,
                            },
                        };
                        onChange(body);
                    }}
                ></textarea>
            </div>
        );
    }
}
