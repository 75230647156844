const routers = [
  {
    path: "/",
    exact: false,
    redirect: "/auth",
  },
  {
    component: "TermsLayout",
    path: "/platform-admin-terms-and-conditions",
    auth: false,
    exact: false,
    name: "platformAdminTermsAndConditions",
    redirect : "/platform-admin-terms-and-conditions/",
    childrens: [
      {
      component: 'Terms',
      componentPath: "pages/terms/Terms",
      path: '/',
      name: "Terms",
      auth: false,
      exact: true,
      }
    ]
  },
  {
    component: "TermsLayout",
    path: "/privacy-statement",
    auth: false,
    name: "privacyStatement",
    exact: false,
    redirect : "/privacy-statement/",
    childrens: [
      {
      component: 'Privacy',
      componentPath: "pages/privacy/Privacy",
      path: '/',
      name:"Privacy",
      auth: false,
      exact: true,
      }
    ]
  },
  {
    component: "TermsLayout",
    path: "/disclaimer",
    auth: false,
    name: "Disclaimer",
    exact: false,
    redirect : "/disclaimer/",
    childrens: [
      {
      component: 'Disclaimer',
      componentPath: "pages/disclaimer/Disclaimer",
      path: '/',
      name: "Disclaimer",
      auth: false,
      exact: true,
      }
    ]
  },
  {
    component: "AuthLayout",
    path: "/auth",
    auth: false,
    name: "Auth",
    exact: false,
    redirect: "/auth/login/admin",
    childrens: [
      {
        component: "Login",
        componentPath: "pages/Auth/Login",
        path: "/login/:userType",
        name: "Login",
        auth: false,
        exact: true,
      },
      {
        component: "ForgetPassword",
        componentPath: "pages/Auth/ForgetPassword",
        path: "/forgetPassword",
        name: "ForgetPassword",
        auth: false,
        exact: true,
      },
    ],
  },
  {
    component: "PlainLayout",
    path: "/plain",
    auth: false,
    name: "Plain",
    exact: false,
    redirect: "/plain/token/:token",
    childrens: [
      {
        component: "UserVerify",
        componentPath: "pages/Auth/UserVerify",
        path: "/token/:token/:id",
        name: "Token",
        auth: false,
        exact: true,
      },
    ]
  },
  {
    component: "AdminLayout",
    path: "/admin",
    auth: true,
    name: "AdminLayout",
    redirect: "/admin/dashboard",
    exact: false,
    childrens: [
      {
        component: "Dashboard",
        path: "/dashboard",
        componentPath: "pages/Admin/Dashboard",
        name: "mainDashboard",
        auth: false,
        exact: true,
      },
      {
        component: "UserManagement",
        path: "/userManagement",
        componentPath: "pages/Admin/UserManagement",
        name: "userManagementPanel",
        auth: false,
        exact: true,
      },
      {
        component: "AddCoAdminClass",
        path: "/userManagement/:action",
        componentPath: "pages/Admin/UserManagement/AddCoAdmin",
        name: "addCoAdmin",
        auth: false,
        exact: true,
      },
      {
        component: "JobCategories",
        path: "/jobCategories",
        componentPath: "pages/Admin/JobCategories",
        name: "jobCategories",
        auth: false,
        exact: true,
      },
      {
        component: "Clients",
        path: "/client",
        componentPath: "pages/Admin/Clients",
        name: "clientsPanel",
        auth: false,
        exact: true,
      },
      {
        component: "ViewClient",
        path: "/client/:client_id",
        componentPath: "pages/Admin/Clients/ViewClient",
        name: "viewClientForm",
        auth: false,
        exact: true,
      },
      {
        component: "Agencies",
        path: "/agencies",
        componentPath: "pages/Admin/Agencies",
        name: "agenciesPanel",
        auth: false,
        exact: true,
      },
      {
        component: "AddAgencyForm",
        path: "/agencies/add/new",
        componentPath: "pages/Admin/Agencies/AddAgency",
        name: "addAgencyForm",
        auth: false,
        exact: true,
      },
      {
        component: "ViewAgency",
        path: "/agencies/:agency_id",
        componentPath: "pages/Admin/Agencies/ViewAgency",
        name: "viewAgencyForm",
        auth: false,
        exact: true,
      },
      {
        component: "EditAgencyForm",
        path: "/agencies/edit/:agency_id",
        componentPath: "pages/Admin/Agencies/EditAgency",
        name: "editAgencyForm",
        auth: false,
        exact: true,
      },
      {
        component: "Subscription",
        path: "/subscription",
        componentPath: "pages/Admin/Subscription",
        name: "subscriptionPlan",
        auth: false,
        exact: true,
      },
      {
        component: "PaymentTransactions",
        path: "/paymenttransactions",
        componentPath: "pages/Admin/PaymentTransactions",
        name: "PaymentTransactions",
        auth: false,
        exact: true,
      },
      {
        component: "Subscription",
        path: "/subscription/:action",
        componentPath: "pages/Admin/Subscription",
        name: "subscriptionDiscount",
        auth: false,
        exact: true,
      },
      {
        component: "AddPlan",
        path: "/subscription/addPlan/:action",
        componentPath: "pages/Admin/Subscription/AddPlan",
        name: "addPlanDetail",
        auth: false,
        exact: true,
      }, 
      {
        component: "PlanDetail",
        path: "/subscription/viewPlan/:action",
        componentPath: "pages/Admin/Subscription/PlanDetail",
        name: "planDetailPage",
        auth: false,
        exact: true,
      },
      {
        component: "AddDiscount",
        path: "/subscription/addDiscount/:action",
        componentPath: "pages/Admin/Subscription/AddDiscount",
        name: "addDiscountForm",
        auth: false,
        exact: true,
      },
      {
        component: "DiscountDetail",
        path: "/subscription/discount/:action",
        componentPath: "pages/Admin/Subscription/discountDetail",
        name: "discountDetailPage",
        auth: false,
        exact: true,
      },
      {
        component: "ReportManagement",
        path: "/report",
        componentPath: "pages/Admin/Report",
        name: "reportManagement",
        auth: false,
        exact: true,
      },
      {
        component: "Settings",
        path: "/settings",
        componentPath: "pages/Admin/Settings",
        name: "settings",
        auth: false,
        exact: true,
      },
      {
        component: "EditSettings",
        path: "/settings/editSettings",
        componentPath: "pages/Admin/Settings/EditSettings",
        name: "editSettings",
        auth: false,
        exact: true,
      },
      {
        component: "AuditLogs",
        path: "/auditLogs",
        componentPath: "pages/Admin/AuditLogs",
        name: "auditLogs",
        auth: false,
        exact: true,
      },
      {
        component: "TaxSettings",
        path: "/taxSettings",
        componentPath: "pages/Admin/TaxSettings",
        name: "taxSettings",
        auth: false,
        exact: true,
      },
      {
        component: "LoginSessionLogs",
        path: "/userloginsessions",
        componentPath: "pages/Admin/LoginSessionLogs",
        name: "LoginSessionLogs",
        auth: false,
        exact: true,
      },
      {
        component : "AccessControl", 
        path: "/accesscontrol", 
        componentPath : "pages/Admin/AccessControl", 
        auth: false,
        exact:true,
      }
      // {
      //   component: "Notification",
      //   path: "/notification",
      //   componentPath: "pages/Admin/Notification",
      //   name: "notificationPanel",
      //   auth: false,
      //   exact: true,
      // },
    ],
  },
];

export default routers;
