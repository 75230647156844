import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import './sidebar.scss';
import dashboardSolid from 'assets/svg/dashboard solid.svg';
import dashboardTransparent from 'assets/svg/dashboard transparent.svg';
import singleSolid from 'assets/svg/single solid.svg';
import singleTransparent from 'assets/svg/single transparent.svg';
import jobCategory from 'assets/svg/job_category.svg';
import jobCategoryTransparent from 'assets/svg/job_category_white.svg';
import clientSolid from 'assets/svg/Client_solid.svg';
import clientTransparent from 'assets/svg/Client_transparent.svg';
import customerSolid from 'assets/svg/customer solid.svg';
import customerTransparent from 'assets/svg/customer transparent.svg';
import pricetagSolid from 'assets/svg/price-tag solid.svg';
import pricetagTransparent from 'assets/svg/price-tag transparent.svg';
import statSolid from 'assets/svg/stat solid.svg';
import statTransparent from 'assets/svg/stat transparent.svg';
import microscope_blue from 'assets/svg/microscope_blue.svg';
import microscope_white from 'assets/svg/microscope_white.svg';
import userLoginWhite from 'assets/svg/userLoginSessionWhite.svg';
import userLoginBlue from 'assets/svg/userLoginSessionBlue.svg';
import settingsSolid from 'assets/svg/settings solid.svg';
import settingsTransparent from 'assets/svg/settings transparent.svg';
import transactionSolid from "assets/svg/transactionSolid.svg"
import transactionTransparent from "assets/svg/transactionTransparent.svg"
import accessControl  from 'assets/svg/accessControlDarkIcon.svg'
import accessControlTransaprent from "assets/svg/accessControlTransparent.svg"
import taxSettingSolid from 'assets/svg/taxSettingSolid.svg'
import taxSettingTransparent from 'assets/svg/taxSettingTransparent.svg'
import {history} from '../../../service/helpers';
import { OverlayTrigger, Tooltip } from "react-bootstrap"; 
import { update } from 'lodash';

const initialNavLinks = [
  {
    to: '/something',
    label: '',
    iconName: '',
    iconTransparent: '',
    classNames:"",
  },
  {
    to: '/admin/dashboard',
    label: 'Dashboard',
    iconName:dashboardSolid,
    iconTransparent:dashboardTransparent,
    classNames:"sideBarIcon",
  },
  {
    to: '/admin/UserManagement',
    label: 'User Management',
    iconName:singleSolid ,
    iconTransparent:singleTransparent,
    classNames:"sideBarIcon",
  },
  {
    to: '/admin/jobCategories',
    label: 'Job Categories',
    iconName:jobCategory ,
    iconTransparent:jobCategoryTransparent,
    classNames:"sideBarIcon",
  },
  {
    to: '/admin/client',
    label: 'Clients',
    iconName:clientSolid,
    iconTransparent:clientTransparent,
    classNames:"sideBarIcon",
  },
  {
    to: '/admin/agencies',
    label: 'Agencies',
    iconName:customerSolid,
    iconTransparent:customerTransparent,
    classNames:"sideBarIcon",
  },
  {
    to: '/admin/report',
    label: 'Reports',
    iconName:statSolid,
    iconTransparent:statTransparent,
    classNames:"sideBarIcon",
  },
  {
    to: '/admin/subscription',
    label: 'Subscriptions',
    iconName:pricetagSolid,
    iconTransparent:pricetagTransparent,
    classNames:"sideBarIcon",
  },
  {
    to: '/admin/paymenttransactions',
    label: 'Payment Transactions',
    iconName: transactionSolid,
    iconTransparent: transactionTransparent,
    classNames:"sideBarIcon",
  },
  {
    to: '/admin/auditLogs',
    label: 'Audit Logs',
    iconName: microscope_blue,
    iconTransparent: microscope_white,
    classNames:"ALsizePlus",
  },
  {
    to: '/admin/userloginsessions',
    label: 'User Login Sessions',
    iconName: userLoginBlue,
    iconTransparent: userLoginWhite,
    classNames:"ALsizePlus",
  },
  {
    to: '/admin/accessControl',
    label: 'Access Control',
    iconName: accessControl,
    iconTransparent: accessControlTransaprent,
    classNames:"ALsizePlus",
  },
  {
    to: '/admin/taxSettings',
    label: 'Tax Settings',
    iconName: taxSettingSolid,
    iconTransparent: taxSettingTransparent,
    classNames:"sideBarIcon",
  },
  {
    to: '/admin/settings',
    label: 'Settings',
    iconName: settingsSolid,
    iconTransparent: settingsTransparent,
    classNames:"sideBarIcon",
  },
  {
    to: '/something',
    label: '',
    iconName: '',
    iconTransparent: '',
    classNames:"",
  },
]

export default class Sidebar extends Component {
  constructor(props) {
    super(props);
  this.activeTab = this.activeTab.bind(this);
  this.state = {
    prev:'', 
    navLinks:initialNavLinks,
    next:'',
    active:'',
    userPermission:{}
  };
}
getCurrentClassName = (to,prev,next,current,location) => {
  this.state.navLinks.forEach((item,index)=>{
        if( location.pathname.toLowerCase().includes(item.to.toLowerCase()) ){
            next = index+1;
            prev = index-1;
            this.state.active = index;
        }
    });
  let className = "nav-link";
  className += to=='/something'?' disabled ':'';
  className += (prev==current && location.pathname !=='/admin/notification') ?' prev-li ':'';
  className += (next==current && location.pathname!=='/admin/notification') ?' next-li ':'';
  return className;
}
  
activeTab=(index)=>{
  
  let{location}=history;

  let matchData=location.pathname.split('/')

  if(matchData[matchData.length+1]!='notification'){
    this.setState({
      next: index+1,
      prev:index-1,
      active:index
    },()=>{
    })
  }
  
}

componentDidMount(){ 
  let{location}=history;
  let {navLinks,userPermission,active}=this.state;
  let permission=JSON.parse(localStorage.getItem("userData"));
  userPermission=permission.userPermission;
  let navArray = [];
  initialNavLinks.forEach((item,index)=>{
    let flag = index===0?1: index ===1 ? userPermission.isDashboard : index ===2 ? userPermission.isCoAdmin:index ===3 ? userPermission.isJobManagement : index === 4? userPermission.toClient : index ===5 ? userPermission.isAgency:index === 6?userPermission.isReports:index === 7 ?userPermission.isSubscription:index === 8 ?userPermission.isPaymentTransactions: index === 9 ? userPermission.isAudit: index === 10 ? userPermission.isLoginSession: index === 11 ? userPermission.isAccessControl : index === 12 ? userPermission.isTaxSettings : index == 13 ? userPermission.isSettings:1;
    if(flag === 1||permission.userType==1){
      navArray.push(item);
    }
    navArray.forEach((item,index)=>{
        if( item.to  === location.pathname ){
          this.setState({
            next: index+1,
            prev:index-1,
            active:index
           });
        }
    });
    this.setState({
      navLinks:navArray
    })  
  });
} 


componentDidUpdate(prevProps){ 
  if(prevProps.permissionList !== this.props.permissionList)
 { 
    let{location}=history;
  let updatedNavLinks = initialNavLinks.filter((linkObj)=> { 
    if(linkObj.label == ""){
      return true 
    } 
    else {
    return this.props.permissionPaths.includes(linkObj.to)}
  } 
  ) 
  updatedNavLinks.forEach((item,index)=>{
    if(item.to === location.pathname){
      this.setState({
        next: index+1,
        prev:index-1,
        active:index
       });
    }
  }) 
  this.setState({navLinks:updatedNavLinks})
}}

  render() {
    let { navLinks,prev,next,active,userPermission } = this.state;
    let { menuOpen, handleSidenav, permissionList } = this.props;
    let {location}=history


    
    return (
      <>
        <div className={`left-menu bg-site-primary  ${menuOpen ? 'open' : ''}`}>
          <div className="sidebar-logo">
            {/* <img src={logo} alt="logo" /> */}
          </div>
          <PerfectScrollbar>
             <ul>
               {
                 navLinks.map(({ to, label, iconName,iconTransparent, classNames }, index) => (
                  <li key={index} >
                    <NavLink onClick={(to!='/something'?()=>this.activeTab(index):'')} to={to} tabIndex={to=='/something'?'-1':''} className={this.getCurrentClassName(to,prev,next,index,location)}>
                      <div className="sidebar-menu">
                        <div className="menu-icon text-left">
                        {
                          !menuOpen ? (
                            <OverlayTrigger
                              placement="right"
                              overlay={<Tooltip id={`tooltip-${index}`}>{label}</Tooltip>}
                            >
                              <img
                                src={active === index && location.pathname !== "/admin/notification" ? iconName : iconTransparent}
                                className={classNames}
                              />
                            </OverlayTrigger>
                          ) : (
                            <img
                              src={active === index && location.pathname !== "/admin/notification" ? iconName : iconTransparent}
                              className={classNames}
                            />
                          )
                        }
                        </div>
                        {menuOpen && <span className="sidebar-menu-desc fs-16">{label}</span>}
                        {/* {menuOpen?<span className="sidebar-menu-desc fs-16">{label}</span>:<div className='hoverTitle'><span>{label}</span></div>} */}
                      </div>
                    </NavLink>
                  </li>
                ))
               }
            </ul>
          </PerfectScrollbar>
        </div>
        <div className={`sidemenu-overlay ${menuOpen ? 'open' : ''}`} onClick={() => handleSidenav()}></div>
      </>
    );
  }
}
