import React from 'react';
import { Modal,ModalHeader, ModalBody, ModalFooter } from 'react-bootstrap';
import './style.scss';

export class NormalModal extends React.Component {


    render() {

        var {
            children,
            isOpen,
            toggle,
            centered,
            className = '',
            verticalCenter = true,
            footerContent = '',
            headerClass = '',
            bodyClass = '',
            footerClass = '',
            toggleKey,
            loading = false,
            renderHeader = '',
            size='lg'
        } = this.props




        return (

            <Modal show={isOpen} onHide={toggle} centered={centered} className={`${verticalCenter && 'modal-dialog-centered'} ${className}`} size={size} >

                {renderHeader && <Modal.Header
                    className={headerClass}
                    onHide={toggle}
                    closeButton
                >
                        <Modal.Title className={renderHeader ? 'warning-alert':''} id="contained-modal-title-vcenter">

                    {renderHeader ? renderHeader() : ''}
                        </Modal.Title>
                        
                    {/* <span className="icon-close font-sm" onClick={e => toggle(toggleKey)} /> */}
                </Modal.Header>}
                <div className="position-relative">
                    <Modal.Body
                        className={` bg-transparent border-0 ${ bodyClass}`}
                    >
                        {children}
                    </Modal.Body>

                    {footerContent ? <Modal.Footer
                        className={footerClass}
                    >
                        {footerContent()}
                    </Modal.Footer> : ''}

                    {loading ? <div class="upload-loader">
                        <div class="position-relative w-100 h-100">
                            <div class="loader-circle"></div>
                        </div>
                    </div> : ''}

                </div>

            </Modal>

        )
    }
}

