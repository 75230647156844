import React, { useState } from "react";
import search from "assets/svg/search.svg";
import { NormalSelect } from "component/common/index";
import "./style.scss";
import { NormalDropdown } from "../NormalDropdown";

export const TableFilter = ({ filterData,setPage, setFilter,fsWrap=true,placeholder,filterRegion,regionOption,setFilterRegion, filter, isSort = false,adminAudit=false, isView = true, isRegion, isSortByTwo, isRegionTwo, firstItem, multiFilter=false}) => {
  const [searchValue, updateSearchInput] = useState("");
  const [forSearch, setForSearch] = useState("");
  const handleInput = ({ target: { value } }) => {
    updateSearchInput(value);
  };

  return (
    <div className=" align-items-start mt-4 d-flex justify-content-between">
      <div className="d-flex justify-content-start align-items-start">

      
      <form class="input-group search-box  search-input-group mb-3" 
        onSubmit={(e) => {
        e.preventDefault();
        setFilter({ ...filter, search: forSearch });
        setPage(1)
        }}
        >
        <input
            type="search"
            id="searchValue"
            name="searchValue"
            value={forSearch}
            onChange={e=>setForSearch(e.target.value)}
            className="form-control border-right-0 searchInput"
            placeholder={placeholder?placeholder:"Search"}
            aria-label="Search"
            aria-describedby="basic-addon2"
        />
        <div class="input-group-append ">
            <span
            class="input-group-text bg-white border-left-0"
            id="basic-addon2"
            >
            <button
                type="submit"
                className="hidden-button"
            >
                <img className="mailIcon" src={search} alt="Logo" />
            </button>
            </span>
        </div>
      </form>
      {firstItem}
      </div>
      <div className="d-flex align-items-center">
        {
          isSort ?
            <div className="d-flex align-items-center">
              <p className="mb-0 mr-2 fs-13 font-Poppins fw-500">{isRegion ? "Region" : "Sort by"} </p>
              <NormalDropdown
                          onClick={(e) => {
                            setPage(1)
                              setFilterRegion({...filterRegion, view: e.target.value });
                          }}
                          label={filterRegion.view}
                          optionsList={regionOption}
                          className="custom-dropdown border rounded"
                        />
            </div>
            : ''
        }
        
        {adminAudit?
          <div className="d-flex align-items-center ml-3">
          <p className="mb-0 mr-2 fs-13 font-Poppins fw-500"><span>Sort by</span></p>
          <NormalDropdown
          
                          onClick={(e) => {
                            if (typeof setPage === 'function') {
                              setPage(1)
                            }
                            setFilter({ ...filter, view: e.target.value,label:e.target.data.label });
                          }}
                          label={filter.label?filter.label:'All'}
                          optionsList={filterData}
                          className={`custom-dropdown border rounded ${fsWrap?'fs-9':''}`}
                        />
        </div>
        :
        !multiFilter && <div className="d-flex align-items-center ml-3">
          <p className="mb-0 mr-2 fs-13 font-Poppins fw-500 ">{!isRegionTwo ? <span>{isSortByTwo ? "Sort by " : "View"}</span> : "Region"}</p>
          <NormalDropdown
                          onClick={(e) => {
                            if (["active", "inactive", 'review', 'deactive', "all", 'archived', 'de-activated', 'paused','1','0','2',''].includes(e.target.value)) {
                              if (typeof setPage === 'function') {
                                setPage(1)
                            }
                              setFilter({ ...filter, view: e.target.value, label:e.target.data.label });
                            }
                          }}
                          label={filter.label?filter.label:'All'}
                          optionsList={filterData}
                          className="custom-dropdown border rounded"
                        />
        </div> 
        
        }
      </div>
    </div>
  );
};
