import React from 'react';
import "./NormalInput.scss";
import infoIcon from "assets/svg/info.svg";
import locationIcon from "assets/svg/mdi_location_on.png";

export const NormalInput = ({
    className = "form-control",
    placeholder = "",
    label = "",
    onChange,
    onKeyPress,
    enterKeyPress = false,
    value = "",
    name,
    maxlength,
    disabled = false,
    type = 'text',
    isLink = false,
    info=false,
    location=false,
    minLength='',
    
}) => {
    return (
        <div className="normal-input">
            {label !== '' ?
                <div className="fs-17 text-black font-JosefinSans pb-2 mb-1 line-height-20">
                    {label} {info?<img className="ml-4" src={infoIcon}/>:''}
                </div>
                : ''}
                <div className="d-flex align-items-center">
                    {location
                        ?
                        <span className="location-icon">
                            <img className="mr-2" src={locationIcon}/>
                        </span>
                    : ''}    
                    <input
                        className={`fs-13 font-Poppins fw-400 ${isLink ? `${className} islink` : className } `}
                        name={name}
                        type={type}
                        disabled={disabled}
                        value={value}
                        min={0}
                        maxlength={maxlength}
                        minLength={minLength}
                        placeholder={placeholder}
                        onKeyPress={e => {
                            if(enterKeyPress)
                                onKeyPress(e)
                            }}
                        onChange={e => {

                            let body = {}

                            body = {
                                target: {
                                    name: e.target.name,
                                    value: e.target.value
                                }
                            }
                            onChange(body)
                        }}
                    />
            </div>
            {isLink
                ?
                <span className="link-area">
                    <i className="fs-24 icon-link"></i>
                </span>
                : ''}
        </div>
    )
}