import Filter from 'component/AccessControl/Filter';
import { CommonPageLoader,  NormalCheckbox,  NormalInput, NormalRadio, NormalSelect,  TableFilter, TableWrapper, Title } from 'component/common'
import React, { Fragment, useEffect, useState } from 'react'
import { getAllApiEndPoints, getApiEndPoint, addApiEndPoint,updateApiEndPoint,deleteApiEndPoint,exportApiAccessControlAsCSV,exportApiAccessControlAsJSON } from 'action/AccessControl';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import "./style.scss"
import copyIcon from "assets/svg/dulplicatecopyblueIcon.svg"
import editBlue from 'assets/svg/editblueIcon.svg';
import deleteIcon from 'assets/svg/delete-icon.svg'
import cancelIcon from "assets/svg/cancelIcon.svg"
import checkBtn from "assets/svg/checkBtn.svg"
import exportIcon from "assets/svg/exportIcon.svg" 
import importIcon from "assets/svg/importIcon.svg" 
import agencyCoadminIcon from 'assets/svg/agencyCoadminIcon.svg' 
import agencymanagerIcon from 'assets/svg/agencymanagerIcon.svg'
import platformCoadminIcon from 'assets/svg/platformCoadminIcon.svg' 

import { OverlayTrigger,Tooltip  } from 'react-bootstrap';
import ConfirmationModal from 'component/AccessControl/ConfirmationModal';
import _, { iteratee } from 'lodash';



const headerDetailsTemp = [
    {label:""},
    {
        label: "API URL",
        isSort: true,
        type: 'string',
        isAsc: 1,
        key: 'endPoints'
    } , 
    { 
        label : "Type",
        isSort:true,
        type:'string',
        isAsc:1,
        key:"type"
    }, 
    { 
        label : "Visibilty"
    }, 
    { 
        label : "User Type",
        includeInfoIcon : true
    }, 
    { 
        label : "User Permission" , 
        includeInfoIcon : true
    }, 
    { 
        label : "Actions"
    }
] 

export const userTypeList = [
    {label : "Platform Super Admin", value: 1},
    {label : "Platform Co-Admin", value :2},
    {label:"Agency Admin", value :3},
    {label : "Agency Co-Admin", value :4},
    {label: "Agency Manager", value :5},
{   label: "Client",  value :6},
    {label:"Staff Worker", value :7}
]

export const userPermissionList = [
    { 
        userType: {label:"Agency Co-Admin", value:"agencyCoAdminPermission" ,icon:agencyCoadminIcon },
        permissionList : [
            {label:"Permit to Manage staff team", value:"permitManageStaffAndTeam"}, 
            {label: "Permit to Work request", value:"toWorkRequest"}, 
            {label:"Permit to TimeSheet", value:"toTimeSheet"}, 
            {label:"Permit to Invoive", value:"toInvoice"}, 
            {label:"Permit to Dashboard", value:"toDashboard"},
            {label:"Permit to Client", value:"toClient"}, 
            {label:"Permit to Logs", value:"toLogs"},
            {label:"Permit to Behalf staff", value:"onBehalfStaff"},
            // {label:"Permit to Behalf Client", value:"onBehalfClient"},
            {label:"Permit to onBoard CoAdmin Manager", value:"onBoardCoAdminManager"},
            // {label:"Permit to Timezone", value:" timeZone"},
            // {label:"Permit to Notification", value:"notification"},
            // {label:"Permit to View Schedule", value:"viewSchedule"},
            // {label:"Permit to Manage Schedule", value:"manageSchedule"},
            // {label:"Permit to Work Request",value:"workrequest"}, 
            // {label:"Permit to Peer Co-Admin", value:"toPeerCoAdmin"}
        ]
    }, 
    { 
        userType:{label:"Agency Manager",value:"agencyManagerPermission", icon:agencymanagerIcon},
        permissionList : [
            {label:"Permit to PermitManage", value:"permitManage"},
            {label: "Permit to Work request", value:"toWorkRequest"}, 
            {label:"Permit to TimeSheet", value:"toTimeSheet"}, 
            {label:"Permit to Client", value:"toClient"}, 
            {label:"Permit to Behalf staff", value:"onBehalfStaff"},
            // {label:"Permit to Behalf Client", value:"onBehalfClient"},
            // {label:"Permit to View Schedule", value:"viewSchedule"},
            // {label:"Permit to Manage Schedule", value:"manageSchedule"},
            // {label:"Permit to Notification", value:"notification"},
            // {label:"Permit to Peer Manager", value:"toPeerManager"}
        ]
    }, 
    {
        userType:{label:"Platform Co-Admin",value:"platformCoAdminPermission", icon:platformCoadminIcon}, 
        permissionList : [
            {label:"Permit to Dashboard", value:"isDashboard"},
            {label:"Permit to Subscription", value:"isSubscription"},
            {label:"Permit to Audit Logs", value:"isAudit"},
            {label:"Permit to Settings", value:"isSettings"},
            {label:"Permit to Agency", value:"isAgency"},
            {label:"Permit to Co-Admin", value:"isCoAdmin"},
            {label:"Permit to Job Management", value:"isJobManagement"},
            {label:"Permit to Login Sessions", value:"isLoginSession"},
            {label:"Permit to Client", value:"toClient"},
            {label:"Permit to Payment Transactions", value:" isPaymentTransactions"},
            {label:"Permit to Reports", value:"isReports"}
        ]
    }
]

const typesList = [ 
    {label:"GET", value:"get"}, 
    {label:"POST", value:"post"},
    {label:"PUT", value:"put"}, 
    {label:"DELETE", value:"delete"},
    {label:"UPDATE",value:"update"}
] 

const initalEndPointState = { 
    endPoints:"",
    description:"",
    permission:null,
    permittedUserType:[],
    public:true,
    type:'get'
,}

function AccessControl(props) {
    const [data,setData] = useState([])
    const [loading,setLoading] = useState(false)
    const [filter, setFilter] = useState({type:"", visibilty:"",search: ''});
    const [page, setPage] = useState(1); 
    const [paginationConfig, setPaginationConfig] = useState({TotalCount: 0,pageCount: 10,btnDisplay: 5,itemCount: 0,});  
    const [headerDetails, setHeaderDetails] = useState(headerDetailsTemp);
    const [editIndex, setEditIndex] = useState(-1) 
    const [newEndPointIndex, setNewEndPointIndex] = useState(-1)
    const [endPointState,setEndPointState] = useState(initalEndPointState)  
    const [confirmationModal,setConfirmationModal] = useState({open:false, action:"",data:{},index:-1,isCopy:false})
    const [duplicateCopy,setDuplicateCopy] = useState({open:false,count:1,index:-1,data:{}})
    const [copiedValues,setCopiedValues] = useState([{index:-1,data:{}}]) 
    const [exportData,setExportData] = useState({open:false,type:""})
    const [selectedEndPoints,setSelectedEndPoints] = useState({indexs:[]})


    const handleSort = (type, isAsc, key, index) => {
        let tempData = [...data];
        if (type === 'string') {
          tempData.sort((a, b) => {
            if (a[key].toLowerCase() < b[key].toLowerCase()) {
              return isAsc === 1 ? -1 : 1;
            }
            if (a[key] > b[key]) {
              return isAsc === 1 ? 1 : -1;
            }
            return 0;
          });
        } else {
          if (isAsc === 1) {
            tempData.sort((a, b) => parseFloat(a[key]) - parseFloat(b[key]));
          } else {
            tempData.sort((a, b) => parseFloat(b[key]) - parseFloat(a[key]));
          }
        }
        let tempHeader = [...headerDetails];
        tempHeader[index].isAsc = headerDetails[index].isAsc === 1 ? 0 : 1;
        setHeaderDetails(tempHeader);
        setData(tempData); 
      } 

    const handleChange = (event,isCopy, index)=>{ 
        
        const target = event.target
        const name = target.name;
        // console.log(copiedValues,isCopy,index,copiedValues.filter((copy)=>{ return copy.index === index}))
        let currentRowState = isCopy ? copiedValues.filter((copy)=>{ return copy.index === index})[0].data : endPointState;
        // console.log(copiedValues,isCopy,index,copiedValues.filter((copy)=>{ return copy.index === index}))
        let data = {...currentRowState}
        data[name] = event.target.value;  
        // console.log(data)
        if(isCopy){
            let temp = [...copiedValues] 
            temp.map((copy)=>{if(copy.index === index){copy.data = data}})
            setCopiedValues(temp)
        }
        else {
            setEndPointState(data)
        }
    } 

    const handleRemove = (rowIndex) =>{
        if(confirmationModal.isCopy){
            let tempCopy = copiedValues.filter((copy)=>{return copy.index !== rowIndex })
            setCopiedValues([...tempCopy])
            let tempData = data.filter((_,index)=> {return index !== rowIndex})
            setData([...tempData]) 
        } 
        if(confirmationModal.action === "ADD" && !confirmationModal.isCopy ){
            setNewEndPointIndex(-1)
            let tempData = data.pop()
            setData([...tempData])
            setEndPointState(initalEndPointState)
            }

        if(confirmationModal.action === "EDIT" || selectedEndPoints.length>1 ){
            setEditIndex(-1) 
            setEndPointState(initalEndPointState)
        }

    }


    const handleDownloadData = async (type) => {
        // console.log(type)
        // setDownloading(true);
        if(type === "CSV"){
            await props
            .exportApiAccessControlAsCSV()
            .then((data) => {
              const blob = new Blob([data], { type: 'text/csv' });
              const a = document.createElement("a");
              a.style.display = "none";
              document.body.appendChild(a);
              const url = window.URL.createObjectURL(blob);
              a.href = url;
              a.setAttribute("download", "AccessControCSV");
              a.click();
      
              document.body.removeChild(a);
              // setDownloading(false);
              setExportData({...exportData,open:false})
            })
            .catch(() => {
              // setDownloading(false);
              setExportData({...exportData,open:false})
            })
        }
        if(type === "JSON"){
            await props
            .exportApiAccessControlAsJSON()
            .then((data) => {
              const blob = new Blob([JSON.stringify(data)], { type: 'application/json' });
              const a = document.createElement("a");
              a.style.display = "none";
              document.body.appendChild(a);
              const url = window.URL.createObjectURL(blob);
              a.href = url;
              a.setAttribute("download", "AccessControJSON");
              a.click();
      
              document.body.removeChild(a);
              setExportData({...exportData,open:false})
              // setDownloading(false);
            })
            .catch((err) => {
              // setDownloading(false);
              setExportData({...exportData,open:false})
              console.log(err)
            });
        }
      };

    const fetchAllEndPonits = async()=>{ 
        setLoading(true)
        let query={} 
        query ={ 
            page:page, 
            Search:filter.search,
            size:paginationConfig.pageCount
        } 

        await props.getAllApiEndPoints(query)
        .then((data)=>{setData(data.data) 
        let count = data.page * paginationConfig.pageCount;
        setPaginationConfig({ ...paginationConfig, TotalCount: count, itemCount: data.data.length });
        setLoading(false)}) 
        .catch((err)=>{console.log(err)})

    }  

    const handleAddApiEndPoint = async(isConfirm)=>{
        if(isConfirm){
        let temp = {...endPointState} 
        // console.log(endPointState, "running")
        if(!!temp.permission){
            let alltypes = userPermissionList.map((item)=>item.userType.value)
            // console.log(alltypes)
            for (let i of alltypes) {
                temp.permission[i] = temp.permission[i] ? temp.permission[i] : null 
            }
            if(Object.values(temp.permission).every(value => value === null || (Array.isArray(value) && value.length === 0))) 
            {
                temp.permission = null 
            } 
        } 
        if(!!temp.permittedUserType) {
            if(temp.permittedUserType.length === 0) {
                temp.permittedUserType = null
            }
        } 

        let body = {...temp} 

        await props.addApiEndPoint(body).
        then(()=>{
          if(confirmationModal.isCopy){
            let temp = [...copiedValues] 
            let filterTemp = temp.filter((copy)=>{return !_.isEqual(copy.data,endPointState) }) 
            setCopiedValues([...filterTemp]) 
            data[confirmationModal.index] = endPointState
            setData([...data])
            setEndPointState(initalEndPointState)
          } 
          else{
            fetchAllEndPonits()
          }

        }) 
        .catch((err)=>{console.log(err)})} 
    else {
        setEndPointState(initalEndPointState)
    }
    } 


    const handleUpdateApiEndPoint = async(isConfirm)=>{
        if(isConfirm){
        
        let temp = {...endPointState} 
        // console.log(endPointState)
        if(!!temp.permission){
            let alltypes = userPermissionList.map((item)=>item.userType.value)
            // console.log(alltypes)
            for (let i of alltypes) {
                temp.permission[i] = temp.permission[i] ? temp.permission[i] : null 
            }
            if(Object.values(temp.permission).every(value => value === null || (Array.isArray(value) && value.length === 0))) 
            {
                temp.permission = null 
            } 
        } 
        if(!!temp.permittedUserType) {
            if(temp.permittedUserType.length === 0) {
                temp.permittedUserType = null
            }
        } 

        let body = {...temp} 

        await props.updateApiEndPoint(body).
        then(()=>{
            fetchAllEndPonits()
            setEditIndex(-1) 
            setNewEndPointIndex(-1)
        }) 
        .catch((err)=>{console.log(err)})
    }
    else {
        setEndPointState(initalEndPointState)
    }
    }


    const handleDeleteApiEndPoint = async(isConfirm)=>{
        if(isConfirm){
        let query= {
            endpoint : endPointState.endPoints
        } 

        await props.deleteApiEndPoint(query).
        then(()=>{
            fetchAllEndPonits()
        })
        .catch((err)=>{
            console.log(err)
        })
    }
    else {
        setEndPointState(initalEndPointState)
    }
    }  

    const handleBulkUpdateApiEndPoint = async(isConfirm)=>{
      isConfirm ? 
      selectedEndPoints.indexs.forEach(async(id,index)=>{
        let temp = {...endPointState} 
        // console.log(endPointState)
        if(!!temp.permission){
            let alltypes = userPermissionList.map((item)=>item.userType.value)
            // console.log(alltypes)
            for (let i of alltypes) {
                temp.permission[i] = temp.permission[i] ? temp.permission[i] : null 
            }
            if(Object.values(temp.permission).every(value => value === null || (Array.isArray(value) && value.length === 0))) 
            {
                temp.permission = null 
            } 
        } 
        if(!!temp.permittedUserType) {
            if(temp.permittedUserType.length === 0) {
                temp.permittedUserType = null
            }
        }  

        if(id !== editIndex){
            const unEffectedList = ['endPoints','description','type']
            for( let key of unEffectedList){
                temp[key] = data[id][key]
            }
        }

        let body ={...temp}
        // console.log(body)
     
        await props.updateApiEndPoint(body)
        .then()
        .catch((err)=>{console.log(err)}) 
        .finally(
            async ()=>{
                if(index === selectedEndPoints.indexs.length-1)
                {
                    setSelectedEndPoints({indexs:[]})
                    setEditIndex(-1) 
                    setNewEndPointIndex(-1)
                    await fetchAllEndPonits()
                }
            }
        ) 
         })

      : 

      await handleUpdateApiEndPoint(true)
    // console.log(endPointState)

    } 





const allActionHandlers = {
    "ADD" : handleAddApiEndPoint,
    "EDIT" : handleUpdateApiEndPoint,
    "DELETE":handleDeleteApiEndPoint,
    "BULKEDIT":handleBulkUpdateApiEndPoint
}


    useEffect(()=>{
        fetchAllEndPonits()
        setEditIndex(-1)
        // Setting all states to deafult
        setEndPointState(initalEndPointState)
        setSelectedEndPoints({indexs:[]})
        setConfirmationModal({open:false, action:"",data:{},index:-1,isCopy:false})
        setDuplicateCopy({open:false,count:1,index:-1,data:{}})
        setCopiedValues([{index:-1,data:{}}])

    },[page,filter,paginationConfig.pageCount]) 




  return (
    <div className='d-flex pl-4 flex-column'> 
    <div className=' d-flex justify-content-between w-100'>
    <Title label="Access Control" />
    <div className='d-flex'>
        <div className='position-relative'>
        <div className='custom-button export' 
        tabIndex="0"
         onClick={()=>{setExportData({...exportData,open:true})}} 
        //   onBlur={()=>{
        //     setExportData({...exportData,open:false})}}
        >
            <img src={exportIcon} alt='exportIcon' />
            Export
        </div> 
        {exportData.open && <div className='position-absolute exporttypes'  

        > 
           <div onClick ={()=>{handleDownloadData("CSV")}} className='mb-1'>CSV</div> 
           <div onClick ={()=>{handleDownloadData("JSON")}}>JSON</div>
        </div>}

         </div>


        <div className='custom-button import' onClick={()=>{}}>
            <img src={importIcon} alt='importIcon'/>
            Import 
        </div>
       

    </div>
    </div>
    {/* <div className='d-flex'> 
    <form class="input-group search-box  search-input-group " 
        onSubmit={(e) => {
        e.preventDefault();
        }}
        >
        <input
            type="search"
            id="searchValue"
            name="searchValue"
            value={filter.search}
            onChange={e=>setFilter({search:e.target.value})}
            className="form-control border-right-0 searchInput"
            placeholder={"Search"}
            aria-label="Search"
            aria-describedby="basic-addon2"
        />
        <div class="input-group-append ">
            <span
            class="input-group-text bg-white border-left-0"
            id="basic-addon2"
            >
            <button
                type="submit"
                className="hidden-button"
            >
                <img className="mailIcon" src={search} alt="Logo" />
            </button>
            </span>
        </div>
      </form> 

    <div className='pl-5 '
    onClick={()=>setIsOpen(!open)}>
        Filters 
        <div className="mt-2" >
        </div>
    </div>  
   


    </div>  */}

<TableFilter
        setFilter={setFilter}
        filter={filter}
        fsWrap={false}
        multiFilter={true}
        isSort={false}
        setPage={setPage}
        firstItem={<Filter filter={filter} setFilter={setFilter}/>}
      />
    <div className='mb-4'>
    <TableWrapper
          headerDetails={headerDetails}
          // queryHandler={handleAllStories}
          handleSort={handleSort}
          pageMeta={{}}
          page={page}
          paginationChange={(page) => setPage(page)}
          paginationConfig={paginationConfig}
          pageCountChange={(count) => setPaginationConfig({ ...paginationConfig, pageCount: Number(count) })}
          paginationInDown ={true}
        > 
        { !loading ? 
        (<> 
        {  
        data.map((item,index)=>{ 
            let 
            {endPoints,description,permission,permittedUserType,type} = item 
            let isCopy = copiedValues.map((copy)=>{return copy.index}).includes(index)
            let isEditable = index === editIndex || newEndPointIndex === index ||isCopy 
            let currentRowState = isCopy ? copiedValues.filter((copy)=>{ return copy.index === index})[0].data : endPointState
            let setCurrentRowState = (newObject)=> 
            {
            if(isCopy){ 
                // console.log(newObject)
                let temp = [...copiedValues] 
                temp.map((copy)=>{if(copy.index === index){copy.data = newObject}})
                setCopiedValues(temp)
            }
            else {
            setEndPointState(newObject)}}
            
            return ( 
                <Fragment key={index}> 
                <tr>
                    <td>
                        <NormalCheckbox 
                        onChange={()=>{
                            selectedEndPoints.indexs.includes(index) ?
                            setSelectedEndPoints({...selectedEndPoints,indexs:selectedEndPoints.indexs.filter((i)=>{return i !== index})}) : 
                            setSelectedEndPoints({...selectedEndPoints,indexs:[...selectedEndPoints.indexs,index]})
                        }} 
                        selected={selectedEndPoints.indexs.includes(index)}
                        />
                    </td> 
                    <td>
                        <div>
                           {isEditable ? 
                            <div className='d-flex flex-column '>
                                <NormalInput 
                                placeholder='Paste URL here' 
                                value={currentRowState.endPoints?currentRowState.endPoints : ""}
                                className='d-flex w-100 apiUrl p-2'
                                name = "endPoints"
                                disabled={true}
                                onChange={(e)=>{handleChange(e,isCopy,index)}}/>
                                <NormalInput 
                                placeholder='Enter the purpose of URL' 
                                value={currentRowState.description?currentRowState.description:""} 
                                name="description"
                                className='description edit-description p-2'
                                onChange={(e)=>{handleChange(e,isCopy,index)}}/>
                            </div> 
                        : <div className='d-flex flex-column'>
                           <div className='apiUrl'> {endPoints}</div> 
                           <div className='description'>{description?description:"No purpose has been described"} </div>
                           </div>
                           }
                        </div>
                    </td> 
                    <td > 
                        <div className={`${isEditable ? 'type-edit':""}`}>
                            {
                                isEditable ?                 
                                <NormalSelect 
                                placeholder="Select the type"
                                value={currentRowState.type? currentRowState.type:""}
                                options={typesList}
                                name="type"
                                handleChange={(e)=>handleChange(e,isCopy,index)}/> 
                                : 
                                <div className='apiUrl d-flex justify-content-center '>{typesList.filter((item)=>{return item.value === type})[0].label}</div>
                            }
                        </div>
                    </td> 
                    <td>
                        <div>
                            {
                              isEditable ? 
                              console.log(currentRowState) ||

                                <div className='d-flex flex-column cursor-pointer'> 
                                    <div className={`visibilty ${currentRowState.public?'public-selected':'unselected-visibilty'}`} onClick={()=>{ console.log(currentRowState);
                                        setCurrentRowState({...currentRowState,public:true})}}>Public</div> 
                                    <div className={`visibilty ${!currentRowState.public?'private-selected':'unselected-visibilty'}`} onClick={()=>setCurrentRowState({...currentRowState,public:false})}>Private</div>
                                </div>
                                                                : 
                                <div className={`${item.public?'public-selected':'private-selected'} visibilty`}>{
                                    item.public?"Public":"Private"} </div>
                            }    
                        </div>
                    </td> 
                    <td>
                        {
                           isEditable ? 
                            <div className='d-flex gap-4 '> 
                            <div className ={`cursor-pointer ${!!currentRowState.permittedUserType && currentRowState.permittedUserType.length === 7 ? 'selected-all' : 'frame-2886'}`} 

                            onClick={()=>{currentRowState.permittedUserType.length === 7 ?
                                 setCurrentRowState({...currentRowState,permittedUserType:[]}): 
                                 setCurrentRowState({...currentRowState,permittedUserType:[1,2,3,4,5,6,7]})} }
                        >All</div>
                         {userTypeList.map((type,index)=>{ 
                                return (
                                    <>
                                       <OverlayTrigger 
                                        placement="top"
                                        overlay={
                                        <Tooltip>
                                        <div className='d-flex '> 
                                        <div className='frame-2866'>
                                            {type.value}</div>&nbsp;{userTypeList[index].label} </div>
                                        </Tooltip>}>
                                <div 
                                className={` cursor-pointer ${!!currentRowState.permittedUserType && currentRowState.permittedUserType.includes(type.value)? `selected-${type.value}` : 'frame-2886'}`} 
                                onClick={()=>{
                                    currentRowState.permittedUserType.includes(type.value)? 
                                    setCurrentRowState({...currentRowState,permittedUserType:currentRowState.permittedUserType.filter(item=>item !== type.value)}): 
                                    setCurrentRowState({...currentRowState, permittedUserType:[...currentRowState.permittedUserType,type.value]})
                                }}
                                 >
                                    {type.value}
                                    </div>
                                </OverlayTrigger>
                                </>)
                            })
                            }
                            </div>
                            : 
                            <div className='d-flex gap-4'>
                            <div className={`${!!permittedUserType && permittedUserType.length === 7 ? 'selected-all' : 'frame-2886'}`}
                        >All</div>
                            {userTypeList.map((type,index)=>{ 
                                return (
                                    <>
                                       <OverlayTrigger 
                      placement="top"
                      overlay={<Tooltip>
                        <div className='d-flex '> <div className='frame-2866 color-primary'>{type.value}</div>&nbsp;{userTypeList[index].label} </div>
                      </Tooltip>}>
                                <div 
                                className={`${!!permittedUserType && permittedUserType.includes(type.value)? `selected-${type.value}` : 'frame-2886'}`} 

                                onClick={(e)=>{handleChange(e,isCopy,index)}}
                                 >
                                   {type.value}
                                    </div>
                                </OverlayTrigger>
                                </>)
                            })
                            }
                        </div>

                        }
                       
                    </td> 
                    <td className='w-100'>
                        <div className='d-flex flex-column'>
                            {userPermissionList.map((item)=>{
                                return (
                                    <div className='d-flex'> 
                                    <div className={`frame-2939 ${isEditable && !!currentRowState.permission && currentRowState.permission[item.userType.value] && currentRowState.permission[item.userType.value]?.length === userPermissionList.filter((type)=>{return type.userType.value === item.userType.value})[0].permissionList.length && item.userType.value+'-all' }`}  

                                    onClick={ 
                                     isEditable ?
                                        ()=>{
                                        (!!currentRowState.permission && !!currentRowState.permission[item.userType.value] && currentRowState.permission[item.userType.value].length === item.permissionList.length ) ?
                                        setCurrentRowState({...currentRowState,permission:{...currentRowState.permission,[item.userType.value]:null }}) : 
                                        setCurrentRowState({...currentRowState, permission:{...currentRowState.permission,[item.userType.value]: item.permissionList.map((item)=>{ return item.value})}}) 
                                    } : 
                                        null}
                                    > 
                                    <OverlayTrigger 
                                    placement='top' 
                                    overlay={<Tooltip>
                                        <div>
                                            {
                                                item.userType.value
                                            }
                                        </div>

                                    </Tooltip>}
                                    >
                                        <div className={`${item.userType.value}`}><img src={item.userType.icon}/> </div>

                                    </OverlayTrigger>
                                     </div>
                                        {item.permissionList.map((permissionType,indexvalue)=>{
                                            return (
                                                
                                               <OverlayTrigger 
                                               placement='top' 
                                               overlay={<Tooltip
                                               data-bs-custom-class="custom-tooltip"
                                               >
                                                <div>
                                                    {indexvalue}&nbsp; {userPermissionList.filter((type)=>{return type.userType.value === item.userType.value})[0].permissionList[indexvalue].label}
                                                </div>
                                               </Tooltip>}
                                               >
                                                 <div className={
                                                    isEditable ? 
                                                    `${!!currentRowState.permission && !!currentRowState.permission[item.userType.value] && currentRowState.permission[item.userType.value].length >0 && currentRowState.permission[item.userType.value].includes(permissionType.value)? 'selected-'+item.userType.value :"frame-2886"}`
                                                    :
                                                    `${!!permission && !!permission[item.userType.value] && permission[item.userType.value].length >0 && permission[item.userType.value].includes(permissionType.value)? "selected-"+item.userType.value:"frame-2886"}`
                                                }  
                                                onClick={ 
                                                    index === editIndex || newEndPointIndex === index || copiedValues.map((copy)=>{return copy.index}).includes(index)? 
                                                    ()=>{ 
                                                        !!currentRowState.permission && !!currentRowState.permission[item.userType.value] &&
                                                        currentRowState.permission[item.userType.value].includes(permissionType.value) ? 
                                                        setCurrentRowState({
                                                            ...currentRowState, permission:{ ...currentRowState.permission, 
                                                            [item.userType.value]: currentRowState.permission[item.userType.value].filter((type)=>type!== permissionType.value)}
                                                        })
                                                        : 
                                                        !!currentRowState.permission && !!currentRowState.permission[item.userType.value] ?
                                                        setCurrentRowState({...currentRowState,permission:{...currentRowState.permission,
                                                        [item.userType.value] :
                                                        [...currentRowState.permission[item.userType.value],permissionType.value]}}) : 
                                                        setCurrentRowState({...currentRowState,permission:{...currentRowState.permission,
                                                            [item.userType.value] :
                                                            [permissionType.value]}    
                                                        })
                                                    }
                                                    : 
                                                    null
                                                }
                                                > 
                                                    {indexvalue+1}
                                                </div>
                                               </OverlayTrigger>
                                            )
                                        })}
                                    </div>
                                )
                            })}
                        </div>
                    </td> 
                    <td >
                        <div className='actions'>
                        {isEditable  ? <img src={checkBtn} 
                        onClick={
                            ()=>{
                                console.log(selectedEndPoints)
                                setEndPointState({...currentRowState})
                                selectedEndPoints.indexs.length>1? 
                                setConfirmationModal({...confirmationModal,open:true,action:"BULKEDIT"})
                                :setConfirmationModal({...confirmationModal,open:true,action:confirmationModal.action === "EDIT"?confirmationModal.action:"ADD", isCopy : true, index: index})
                            }}/>
                        :
                        <div className='edit-button'><img src={editBlue} alt="edit"  onClick={()=>{setEditIndex(index) 
                        setEndPointState({...item}) 
                        setConfirmationModal({...confirmationModal,action:"EDIT"})
                    }
                    }/> </div>}
                    {
                        isEditable ? 
                        <div className='delete-button'> 
                        <img src={cancelIcon} onClick={()=>{handleRemove(index)}}/>
                        </div>
                    :
                   <div className='delete-button'>
                   <img src={deleteIcon} onClick={()=>{
                        setConfirmationModal({...confirmationModal,open:true,action:"DELETE", data:{...item}})
                        setEndPointState({...item})
                    }}alt="delete"/>
                    </div> }
                    <div className={`${duplicateCopy.index !== index ? `duplicate-copy-button` :''}`}>
                    {duplicateCopy.index === index && duplicateCopy.open? 
                    <div> 
                        <div className='d-flex '> 
                            <label htmlFor={`duplicates-${index}`} style={{width:"125px",marginBottom:"0"}}>Number of Copies:</label>
                            <input
                            id={`duplicates-${index}`}
                            type="number"
                            value={duplicateCopy.count}
                            onChange={(e) => setDuplicateCopy({...duplicateCopy,count:Number(e.target.value)})}
                            style={{backgroundColor: "#ebebeb", border: "none", borderRadius: "5px", width:"30px"}}
                                                />
                        </div> 
                        <div className='d-flex justify-content-center '>
                            <div
                            onClick={()=>{
                                console.log(duplicateCopy)
                           let copies =  new Array(duplicateCopy.count).fill(0).map((_,index)=>{ 
                            return{ index: duplicateCopy.index + 1 + index, data: data[duplicateCopy.index] }
                             }) 
                           let copiedEndPoints =  Array.from({length:duplicateCopy.count},()=>({...data[duplicateCopy.index]}))
                           let tempData = [...data]
                           tempData.splice(index,0,...copiedEndPoints)
                            setData([...tempData])
                            console.log(copies)
                            setCopiedValues([...copies])
                            setDuplicateCopy({open:false,count:1,index:-1,data:{}})
                            setConfirmationModal({...confirmationModal,isCopy:true})
                            }} 
                            className='copy-save'
                            >Save</div>
                            <div onClick={()=>{setDuplicateCopy({open:false,count:1,index:-1,data:{}})}}
                            className='copy-cancel'
                            >Cancel</div>
                        </div>
                    </div>
                    :
                        <img src={copyIcon}  onClick={()=>{setDuplicateCopy({...duplicateCopy,open:true,index:index,data:item})}}alt="copy"  />}
                    </div>
                    </div>
                    </td>
                </tr>
                
                </Fragment>
            )

        })
        }
        </>)
        :
         <td className="border-0 common-page-loader">
         <CommonPageLoader />
       </td>
        }
            </TableWrapper>  
            {confirmationModal.open && (
                    <ConfirmationModal
                    isOpen={confirmationModal.open}
                    handleAction={allActionHandlers[confirmationModal.action]}
                    toggle={() => {setConfirmationModal({...confirmationModal,open:!confirmationModal.open})}} 
                    info={confirmationModal}
                    />
                )}
    </div> 

    <div className='d-flex  justify-content-center fixed-bottom' 
    onClick={()=>{
        setData([...data,initalEndPointState])
        setNewEndPointIndex(data.length)
        setEndPointState(initalEndPointState)
        setConfirmationModal({...confirmationModal,action:"ADD"})
    }}>
       <div className='custom-button add-more'>+ Add More</div> 
    </div>
    </div>
  )
}


const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
      {
       getAllApiEndPoints,
       getApiEndPoint,
       addApiEndPoint,
       updateApiEndPoint,
       deleteApiEndPoint,
       exportApiAccessControlAsCSV,
    exportApiAccessControlAsJSON},
      dispatch
    );
  };
  
export default connect(mapDispatchToProps, mapDispatchToProps)(AccessControl);
