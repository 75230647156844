import Stomp from 'stompjs';
import SockJS from 'sockjs-client';
import { store } from './helpers';

 function connectToWebSocket(){
  const token = localStorage.getItem('AuthToken')
    const wsUrls = JSON.parse(process.env.REACT_APP_WEBSOCKET);
    let stompClients = {}; 
    wsUrls.forEach((url)=>{
      const ws = new SockJS(url);
      const stompClient = Stomp.over(ws); 
      stompClient.debug = null
      stompClient.connect({Authorization: "Bearer " + token}, 
      function(frame){
        const portString = url.match(/:(\d+)/)[1];
        const portNumber = parseInt(portString.slice(-2));

        if(portNumber) {
          stompClients[portNumber] = stompClient 
          if(portNumber === 99)
             {
              stompClient.subscribe("/users/topic/notify/count",function(data) { 
                let NFCData = JSON.parse(data.body)
                   store.dispatch({type:'UPDATE_NOTIFICATION_COUNT', payload:NFCData.message})
              });       
          } 
          else if (portNumber === 84) { 
            stompClient.subscribe("/users/topic/permissionmenu",function(data) {
              let permissionData = JSON.parse(data.body)
              if(permissionData.message){
                 store.dispatch({type:'UPDATE_PERMISSIONS', payload:permissionData.message}); 
                 let user = JSON.parse(localStorage.getItem('userData')) 
                 user.userPermission = permissionData.message 
                 localStorage.setItem("userData", JSON.stringify(user));
                 
                }
              
                
            }) 
        }  

        store.dispatch({type:'UPDATE_CONNECTION_STATUS', payload:true})

    }})    
      ws.onclose = function() {
        store.dispatch({type:'UPDATE_CONNECTION_STATUS', payload:false}) 
      };
      window.addEventListener('offline', ()=>{
        ws.close()
      })
    })
}
  
export default connectToWebSocket