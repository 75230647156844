import React, { useState } from 'react'
import './style.scss'
import filterIcon from "assets/svg/filterIcon.svg"


let typesList = [
    {label:"POST", value:"post"},
    {label:"PUT", value:"put"},
    {label:"GET", value:"get"},
    {label:"DELETE",value:"delete"},
    {label:"PATCH",value:"patch"}
]  

let visibilityStatusList = [
    {label:"Public", value:"public"},
    {label:"Private", value:"private"}
]

function Filter({filter,setFilter}) { 
    const [open,setOpen] = useState(false) 
    const [type,setType] = useState('') 
    const [visibility, setVisibility] = useState('') 

    const handleSubmit = ()=>{
        setFilter({...filter, type:type,visibility:visibility}) 
        setType('')
        setVisibility('')
        setOpen(false)} 

    const handleCancel =()=>{
        setOpen(false) 
        setType('')
        setVisibility('')
    }

  return ( 
    <div>
        <div className="ml-4 filters custom-button" onClick={()=>{setOpen(!open)}}>
            <div> <img src={filterIcon} alt='filterIcon'/></div>
            <div>Filters</div>
            </div> 
        {open && <div className='filter-box'>
        <div className="frame-2928">
  
  <div className="filter-in">
  <div className="type">Type</div>
  <div className="filter-in-options">
{typesList.map((item, index)=>{
    return ( 
        <div key={index} className={`${type === item.value ? 'frame-2986':'frame-2984'}`} onClick={()=>{setType(item.value)}}>
            <div className={`${type === item.value ? "get" : "post"}`}> {item.label}</div>
        </div>
    )
})}
    </div>

    </div>
  <div className='filter-in'> 
  <div className="visibility-status">Visibility Status</div>
  <div className="filter-in-options"> 
  {visibilityStatusList.map(
    (item, index)=> { 
        return  (<div key={index} className={`${visibility === item.value ? "frame-2986" : 'frame-2984'}`} onClick={()=>{setVisibility(item.value)}}>
          <div className={`${visibility === item.value ? 'private' : 'public'}`}>{item.label}</div>
        </div>)
    }
  )}
  </div>

    </div>
  <div className="filter-submit">
    <div className="custom-button cancel" onClick={handleCancel}>
      <div>Cancel</div>
    </div>
    <div className="custom-button apply" onClick={handleSubmit}>
      <div>Apply</div>
    </div>
  </div>
</div>
            </div>}
    </div>




  )
}

export default Filter