import { addQuery } from "service/helperFunctions"; 
import { accessControlApi } from "service/apiVariables";

export const getAllApiEndPoints = (query) => (
    dispatch,
    getState,
    { api, Toast } 
  ) => {
    return new Promise((resolve, reject) => {
      addQuery(query, accessControlApi.getAllApiEndPoints);
      api({ ...accessControlApi.getAllApiEndPoints })
        .then(( data ) => {
          resolve(data);
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  }; 
  
  
export const getApiEndPoint = (query) => (
    dispatch,
    getState,
    { api, Toast } 
  ) => {
    return new Promise((resolve, reject) => {
      addQuery(query, accessControlApi.getApiEndPoint);
      api({ ...accessControlApi.getApiEndPoint })
        .then(( data ) => {
          resolve(data);
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };  

export const addApiEndPoint = (body) => (
    dispatch,
    getState,
    { api, Toast } 
  ) => {
    return new Promise((resolve, reject) => {
      api({ ...accessControlApi.addApiEndPoint, body })
        .then(({ data ,message}) => {
          resolve(data);
          Toast({ type: "success", message });
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };   

export const updateApiEndPoint = (body) => (
    dispatch,
    getState,
    { api, Toast } 
  ) => {
    return new Promise((resolve, reject) => {
      api({ ...accessControlApi.updateApiEndPoint , body})
        .then(({ data ,message}) => {
          resolve(data);
          Toast({ type: "success", message });
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };    

export const deleteApiEndPoint =  (query) => (
    dispatch,
    getState,
    { api, Toast } 
  ) => {
    return new Promise((resolve, reject) => {
      addQuery(query, accessControlApi.deleteApiEndPoint);
      api({ ...accessControlApi.deleteApiEndPoint })
        .then(( {data,message} ) => {
          resolve(data);
          Toast({ type: "success", message });
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };   



export const exportApiAccessControlAsCSV = (query) => (
    dispatch,
    getState,
    { api, Toast } 
  ) => {
    return new Promise((resolve, reject) => {
      addQuery(query, accessControlApi.exportApiAccessControlAsCSV);
      api({ ...accessControlApi.exportApiAccessControlAsCSV })
        .then(( data ) => {
          resolve(data);
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  }; 

  export const exportApiAccessControlAsJSON = (query) => (
    dispatch,
    getState,
    { api, Toast } 
  ) => {
    return new Promise((resolve, reject) => {
      addQuery(query, accessControlApi.exportApiAccessControlAsJSON);
      api({ ...accessControlApi.exportApiAccessControlAsJSON })
        .then(( data ) => {
          resolve(data);
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  }; 

