import React  from "react";
import "./style.scss";
import {  NormalModal, NormalButton } from "component/common";
import warningIconDisclaimer from "assets/svg/warningDisclaimerIcon.svg"
import warningIcon from "assets/svg/warning-sign.svg"

import closeIcon from "assets/svg/closeCircularIcon.svg"

let defaultMessage ={
    header : "Do you want to do this action",
    body : "Are you sure you want to accept this?",
    confirmLabel: "Yes, Confirm",
    cancelLabel:"No, Cancel"
}


let cases = {
    "DELETE" : 
    {
        icon : warningIconDisclaimer, 
        message : {
            header : "Do you want to delete this item?",
            confirmLabel:"Yes, Delete" },
        color: "#e04d58"
    },
    "BULKEDIT":{
        icon : warningIcon,
        message:{
            header : "Bulk Update",
            body: "It will not reflect for API URL and type fields. Rest of the fields will reflect with your edited data" ,
            confirmLabel:"Apply All",
            cancelLabel:"No, Only This Row"
        },
        color: "#ff8800"
    }, 
    "ADD" : { 
        message:{header : "Do you want to add this item?"},
        color: "#00a86b"

    },
    "EDIT" : {
        message:{header : "Do you want to update details?"},
        color: "#00a86b"
    }
}



function ConfirmationModal(props) { 

//   console.log(props)

  return (
      <NormalModal
        isOpen={props.isOpen}
        size="md"
        toggle={props.toggle} 
        centered={true}
      >
 
          <div>
             <div className="d-flex justify-content-between">
                {props.info.action === "BULKEDIT" || props.info.action === "DELETE" && <div
                >
                    <img src={cases[props.info.action].icon} alt="icon"/>
                </div>} 
                <div className="custom-header-text " style={{color:cases[props.info.action].color}}>
                    {cases[props.info.action].message.header}
                </div> 
                <div>
                    <img  onClick={props.toggle} src={closeIcon} alt="closeIcon"/>
                </div>
            </div>
            <div className=" custom-body-text d-flex justify-content-center m-4">
                {cases[props.info.action].message.body? cases[props.info.action].message.body : defaultMessage.body}
            </div>
          
            <div className="d-flex justify-content-between">
                     <NormalButton
                        onClick={()=>{
                            props.handleAction(false)
                            props.toggle()
                        }
                        }
                        id="no"
                        label={cases[props.info.action].message.cancelLabel?cases[props.info.action].message.cancelLabel:defaultMessage.cancelLabel}
                        className="cancel-custom-btn"
                     />
                     <NormalButton
                        onClick={()=>{
                            props.handleAction(true);
                            props.toggle()
                        }}
                        id="confirm"
                        label={cases[props.info.action].message.confirmLabel?cases[props.info.action].message.confirmLabel:defaultMessage.confirmLabel}
                        className="confirm-custom-btn"
                     />
                  </div>
          </div>

      </NormalModal>
  );
} 



export default ConfirmationModal;
