import { addQuery } from "service/helperFunctions"; 
import { taxSettingsApi } from "service/apiVariables";

export const getTaxRegion = (query) => (
    dispatch,
    getState,
    { api, Toast } ) => {

    return new Promise((resolve, reject) => {
      addQuery(query, taxSettingsApi.getTaxRegion);
      api({ ...taxSettingsApi.getTaxRegion })
        .then(( {data} ) => {
          resolve(data);
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  }; 

  export const addTaxRegion = (body) => (
    dispatch,
    getState,
    { api, Toast } ) => {

    return new Promise((resolve, reject) => {
      api({ ...taxSettingsApi.addTaxRegion, body })
        .then(( {data, status, message} ) => {
          resolve(data);
          if(status){
            Toast({ type: "success", message: message, time: 3000 })
          }
          else{
            Toast({ type: "error", message: message, time: 5000 })
          }
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };

  export const updateTaxRegion = (query) => (
    dispatch,
    getState,
    { api, Toast } ) => {

    return new Promise((resolve, reject) => {
      addQuery(query, taxSettingsApi.updateTaxRegion);
      api({ ...taxSettingsApi.updateTaxRegion })
        .then(( {data, status, message} ) => {
          resolve(data);
          if(status){
            Toast({ type: "success", message: message, time: 3000 })
          }
          else{
            Toast({ type: "error", message: message, time: 5000 })
          }
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };

  export const deleteTaxRegion = (query) => (
    dispatch,
    getState,
    { api, Toast } ) => {

    return new Promise((resolve, reject) => {
      addQuery(query, taxSettingsApi.deleteTaxRegion);
      api({ ...taxSettingsApi.deleteTaxRegion })
        .then(( {data, status, message} ) => {
          resolve(data);
          if(status){
            Toast({ type: "success", message: message, time: 3000 })
          }
          else{
            Toast({ type: "error", message: message, time: 5000 })
          }
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };

  export const getAccountingCodes = (query) => (
    dispatch,
    getState,
    { api, Toast }) => {

    return new Promise((resolve, reject) => {
      addQuery(query, taxSettingsApi.getAccountingCodes);
      api({ ...taxSettingsApi.getAccountingCodes })
        .then(( {data} ) => {
          resolve(data);
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  }; 

  export const updateAccountingCode = (body) => (
    dispatch, 
    getState, 
    { api, Toast }) => {

    return new Promise((resolve, reject) => {
      api({ ...taxSettingsApi.updateAccountingCode, body})
      .then(({ message, data, status }) => {
        resolve(data);
        if(status){
          Toast({ type: "success", message: message, time: 3000 })
        }
        else{
          Toast({ type: "error", message: message, time: 5000 })
        }
    })
    .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
    });
    });
  };

  export const updateState = (body) => (
    dispatch, 
    getState, 
    { api, Toast }) => {

    return new Promise((resolve, reject) => {
      api({ ...taxSettingsApi.updateState, body})
      .then(({ message, data, status }) => {
        resolve(data);
        if(status){
          Toast({ type: "success", message: message, time: 3000 })
        }
        else{
          Toast({ type: "error", message: message, time: 5000 })
        }
    })
    .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
    });
    });
  };

  export const importAccountingCodes = (body, query, responseType) => (
    dispatch, 
    getState, 
    { api, Toast }) => {

    return new Promise((resolve, reject) => {
      addQuery(query, taxSettingsApi.importAccountingCodes);
      api({ ...taxSettingsApi.importAccountingCodes, body, query, responseType})
      .then((response) => {
          resolve(response)
    })
    .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
    });
    });
  };
  
  export const importPlaceOfSupply = (body, query, responseType) => (
    dispatch, 
    getState, 
    { api, Toast }) => {

    return new Promise((resolve, reject) => {
      addQuery(query, taxSettingsApi.importPlaceOfSupply);
      api({ ...taxSettingsApi.importPlaceOfSupply, body, query, responseType})
      .then((response) => {
          resolve(response)
    })
    .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
    });
    });
  };