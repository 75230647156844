import React from "react";
import "./style.scss";
import {
  ButtonDropdown,
  Button,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

export class NormalDropdown extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dropdownId: Math.floor(100000 + Math.random() * 900000),
      isOpen: false,
    };
  }
  handleDropdown = () => {
    // console.log("this is:", mennuId);
    // let submenuElem = document.getElementById(mennuId);
    // submenuElem.classList.toggle("show");
    this.setState({ isOpen: !this.state.isOpen });
  };

  render() {
    const {
      className = "",
      label = "",
      labelIcon = "",
      onClick,
      dropDownClass,
      disabled = false,
      caret = true,
      alinement = "",
      outline = false,
      color = "",
      optionsList = [
        { icon: "edit", label: "Edit", iconClass: "material-icons" },
        { icon: "content_copy", label: "Copy", iconClass: "material-icons" },
        { icon: "delete", label: "Delete", iconClass: "material-icons" },
      ],
      id = "",
    } = this.props;
    let { isOpen } = this.state;

    return (
      <ButtonDropdown
        disabled={disabled}
        isOpen={isOpen}
        toggle={this.handleDropdown}
        className={'dropdown'}
      >
        <DropdownToggle
          disabled={disabled}
          outline={outline}
          color={color}
          className={`dropdown ${className}`}
        >
          {label ? label : <i className={labelIcon}></i>}{" "}
          {className.includes('custom-dropdown')&&<span className="icon-down down-icon"></span>}
        </DropdownToggle>

        <DropdownMenu alinement>
          {optionsList.map((data, index) => (
            <DropdownItem
              className={dropDownClass?dropDownClass:''}
              onClick={(e) => {
                let body = {};
                body = {
                  target: {
                    value: data.value,
                    data: data,
                  },
                };
                // this.handleDropdown(id)
                onClick(body);
              }}
            >
              {data.icon&&<img className="mx-2" src={data.icon} />} <span>{data.label}</span>
            </DropdownItem>
          ))}
          {/* <DropdownItem onClick={this.signOut}>Sign Out</DropdownItem> */}
        </DropdownMenu>
      </ButtonDropdown>
    );
  }
}
