import React, { Component } from "react";
import  Navbar from "component/common/navbar";
import Sidebar from "component/common/Sidebar";
import "assets/scss/layouts/AdminLayout.scss";
import "react-perfect-scrollbar/dist/css/styles.css";
import "react-datepicker/dist/react-datepicker.css";
import "react-datepicker/dist/react-datepicker-cssmodules.css";
import NotificationSettings from 'pages/Admin/Notification';
import { history } from "service/helpers";
import connectToWebSocket from "service/webSocket";
import { connect } from "react-redux";
import _ from "lodash";


let paths = {
  'isDashboard': '/admin/dashboard',
  'isCoAdmin': '/admin/UserManagement',
  'isJobManagement': '/admin/jobCategories',
  'toClient': '/admin/client',
  'isAgency': '/admin/agencies',
  'isReports': '/admin/report',
  'isSubscription': '/admin/subscription',
  'isPaymentTransactions': '/admin/paymenttransactions',
  'isAudit': '/admin/auditLogs',
  'isLoginSession': '/admin/userloginsessions',
  "isAccessControl": '/admin/accessControl',
  'isTaxSettings': '/admin/taxSettings',
  'isSettings': '/admin/settings',
}  


class AdminLayoutComponent extends Component {
  state = {
    profilemenu: false,
    menuOpenClass: false,
    authToken: "",
    permissionList:[],
    isSideBarClicked: false,
    permissionPaths:[]
  };

 
  
  fetchAllPermissions = () => {
    let userData = JSON.parse(localStorage.getItem("userData"))
    let permissionPaths = [];
    for (let key in userData.userPermission) {
      if(userData.userPermission[key] === 1){ 
        permissionPaths.push(paths[key])
      }
    }


      this.setState({ loading: false ,permissionList:userData.userPermission, permissionPaths:permissionPaths})
  }

  handleSidenav = () => {
    let { menuOpenClass } = this.state;

    this.setState(
      {
        menuOpenClass: !menuOpenClass,
      },
      () => {
        // console.log(this.state.menuOpenClass, "MENUCLASS");
      }
    );
  };
  handleSideBar = () => {
    let { isSideBarClicked } = this.state;

    this.setState({
       isSideBarClicked: !isSideBarClicked
    });
 };
  componentDidMount = () => {

    let authToken = localStorage.getItem("AuthToken");

    if (!authToken) {
      history.push("/");
    } else {
      this.setState({ authToken });
      this.fetchAllPermissions();
    }

      connectToWebSocket()
      window.addEventListener('online', ()=>{
        connectToWebSocket()
         })
  }; 

    componentDidUpdate = (prevProps) => {       
      this.hasPermission(window.location.pathname, this.state.permissionPaths)
   if(!_.isEqual(prevProps.permissionsData, this.props.permissionsData)){
    this.updatePermissions(this.props.permissionsData)
 }
  }; 



  componentWillUnmount() {
    const script = document.querySelector(`script[src^="https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}"]`);
    if (script) {
       document.head.removeChild(script);
    }
 } 


 hasPermission(route, permissionList=[]) { 
  const parts = route.split("/");
  let pathName = `/${parts[1]}/${parts[2]}`
  let permit = true;
  permit = permissionList.some((permission => permission.toLowerCase()  === pathName.toLocaleLowerCase()))
 if(!permit && permissionList.length){
  history.push('/admin/settings')
 }}

updatePermissions = (res) =>{
  let data = res;
  let permissionPaths = [] 

  for (let key in data) {
    if(data[key] === 1){ 
      permissionPaths.push(paths[key])
    }
  } 
  this.hasPermission(window.location.pathname, permissionPaths)
  this.setState({loading: false, permissionList:data, permissionPaths:permissionPaths})
}

  handleProfileMenu = (event, active) => {
    event.stopPropagation();
    this.setState({
      profilemenu: active,
    });
  };

  handleSidenav = () => {
    let { menuOpenClass } = this.state;

    this.setState({
      menuOpenClass: !menuOpenClass,
    });
  }; 

  

  render() {
    
    let { children } = this.props;

    let { menuOpenClass,permissionList,permissionPaths } = this.state;

    return (
      <>
      <div id="main-content" onClick={(e) => this.handleProfileMenu(e, false)}>
        <div className="content-wrapper">
          <Sidebar
            menuOpen={menuOpenClass}
            permissionList={permissionList}
            permissionPaths={permissionPaths}
            handleSidenav={this.handleSidenav}
          />
          <Navbar
            // changeProfileState={(e, param) => this.handleProfileMenu(e, param)}
            // active={this.state.profilemenu}
            handleNav={this.handleSidenav}
            handleSideBar= {this.handleSideBar}
            sideBarClickStatus={this.state.isSideBarClicked}
          />
          <div className="content bg-light">{children}</div>
        </div>
      </div>
      <div className={`sidebarNavigation ${this.state.isSideBarClicked ? 'open' : ''}`}>
               <NotificationSettings handleSideBar={this.handleSideBar} sideBarClickStatus={this.state.isSideBarClicked} />
            </div>
      </>
    );
  }
}

const mapStateToProps = (state)=>{
  return {
     permissionsData:state.webSocketReducer.permissions
  }
}
export const AdminLayout = connect(mapStateToProps)(AdminLayoutComponent)