import React, { Component } from "react";
// import { Pagination } from "../index"
import {
  scrollTop,
  appendQuery,
  convertStringToObject,
} from "service/helperFunctions";
import { history } from "service/helpers";
import "./table.scss";
import sortIcon from "assets/svg/sort.svg";
import PropTypes from "prop-types";
import Pagination from "react-js-pagination";
import { NormalCheckbox } from "../NormalCheckbox";
import InfoIcon from "assets/svg/info.svg"
import moment from "moment";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { userPermissionList, userTypeList } from "pages/Admin/AccessControl";


export class TableWrapper extends Component {
  state = {
    currentSortKeyDetails: null,
    paginationactive: Number(this.props.page),
    options: [
      {value:10,label:'10'},
      {value:50,label:'50'},
      {value:100,label:'100'},
      {value:500,label:'500'},
      {value:1000,label:'1000'},
    ],
    keyName: "label",
    valueName: "value",
    placeholder: "Select",
  };

  // componentWillMount() {
  //     let { field = '', orderBy = '' } = this.props;
  //     if (field && orderBy) {
  //         this.setState({
  //             currentSortKeyDetails: { sortKey: field, orderBy },
  //         });
  //     }
  // }

  getPlaceHolder = () => {
    //   let { placeHolder = 'No data found' } = this.props;
    //   if (typeof placeHolder === 'function') {
    //     return placeHolder();
    //   }
    //   return <p className="text-center">{placeHolder}</p>;
  };

  // handlePagination = page => {
  //     let { queryHandler, scrollProps = '' } = this.props;
  //     queryHandler({ page })
  //         .then(() => {
  //             scrollTop(...scrollProps);
  //         })
  //         .catch(err => {
  //             console.log('Error:::' + err);
  //         });
  // };
  handlePageChange(pageNumber) {
    // console.log(`active page is ${pageNumber}`);
    this.setState({ paginationactive: pageNumber });
    this.props.paginationChange(pageNumber);
  }

  render() {
    let {
      handleSort,
      headerDetails,
      paginationConfig,
      pageCountChange,
      paginationLength,
      children,
      pageMeta,
      isEmpty = false,
      className = "",
      overFlow = true,
      page,
      selected,
      setSelected,
      filteredData,
      stickyPagination = false,
      paginationInDown = false
    } = this.props;
    //console.log(paginationConfig, "Sad");
    let { paginationactive = Number(this.props.page) , options, keyName, valueName, placeholder } =
      this.state;
    let { sortKey: currentSortKey, orderBy = "" } =
      this.state.currentSortKeyDetails || {};

    return (
      <div className="maintable table-container">
        <div className={`${stickyPagination ? 'sticky-pagination' : 'div-pagination'} `}>
          <Pagination
            activePage={Number(this.props.page)}
            itemsCountPerPage={
              !!paginationConfig ? paginationConfig.pageCount : 0
            }
            totalItemsCount={
              !!paginationConfig ? paginationConfig.TotalCount : 0
            }
            pageRangeDisplayed={
              !!paginationConfig ? paginationConfig.btnDisplay : 0
            }
            itemClass="page-item"
            linkClass="page-link"
            innerClass=" custom-pagination pagination"
            nextPageText="Next"
            prevPageText="Previous"
            hideFirstLastPages={true}
            linkClassPrev="custom-prev"
            linkClassNext="custom-next"
            disabledClass="custom-disable"
            onChange={this.handlePageChange.bind(this)}
          />
          <div className="total-entries">
            <span className="fs-13 font-Poppins fw-400">Show</span>
            {/* <span className="coount">{!!paginationConfig?paginationConfig.itemCount:0}</span> */}
            <select
              className="custom-hide-option mx-2 fs-14 font-Poppins fw-500"
              value={!!paginationConfig ? paginationConfig.pageCount : 0}
              // disabled={disabled}
              onChange={(e) => {
                pageCountChange(e.target.value);
              }}
            >
              <option value="" disabled defaultValue>
                {placeholder}
              </option>
              {options.length !== 0 ? (
                options.map((option, index) => (
                  <option
                    value={option[valueName]}
                    disabled={
                      option.value > paginationConfig.TotalCount ? true : false
                    }
                    key={index}
                  >
                    {option[keyName]}
                  </option>
                ))
              ) : (
                <option value="" disabled>
                  No Options
                </option>
              )}
            </select>
            <span className="fs-13 font-Poppins fw-400">Entries</span>
          </div>
        </div> 

        {selected?.id?.length>0?<div className="multi-select-warning">        
          Selected {selected.id.length} {selected.type===3?(selected.id.length===1?"agency":"agencies"):selected.type===7?(selected.id.length===1?"client":"clients"):"records"} 
          <button className="delete-btn" onClick={()=>{setSelected({...selected,visible:true})}}>DELETE </button>
        </div>:null}
        <div
          className={`maintable-content px-2 ${
            overFlow ? "table-responsive" : ""
          } ${className}`}
        >
          <table className="table">
            <thead>  
              <tr>
                {headerDetails.map(
                  (
                    {
                      label,
                      description,
                      className,
                      divClass = "",
                      sortKey = "",
                      element,
                      isSort,
                      type,
                      isAsc,
                      key,
                      includeInfoIcon = false
                    },
                    index
                  ) => {
                    return ( 
                      <> 
                      { label==="" && filteredData?.length>0 ? 
                      <th>
                      <div className="d-flex align-items-center justify-content-center">
                       <NormalCheckbox 
                      checked={selected.id.length>0?selected.id.length === filteredData.length:false}
                      label="Select All"
                      onChange={()=>{if(selected.id.length === filteredData.length){setSelected({...selected,id:[]})} 
                      
                      else {
                        let allids = [];
                        filteredData.forEach((item)=>{allids.push(item.id)})
                        setSelected({...selected,id:allids})
                      }
                    }}
                      /> 
                      </div> 
                      </th>    
                      : null}
                      {(includeInfoIcon && label === "User Type")?
                        <OverlayTrigger 
                        placement="left"
                        overlay={<Tooltip className="my-tooltip">
                          <div className="usertype-main">
                          {userTypeList.map((type)=>{
                            return (<div className="usertype-row">
                              <div className={`selected-${type.value}`}>
                                {type.value}</div><div className="usertype-label">{type.label}</div> 
                              </div>
                            )
                          })}
                          </div>
                          </Tooltip>}>
                          <th className={className} key={index}> 
                          <div
                            className={`d-flex h3 align-items-center justify-content-start line-height-24 text-center fs-15 fw-500 ${
                              sortKey && "cursor-pointer"
                            } ${divClass}`}
                          >
                            {label}
                            {element && element()}
                            {isSort ? (
                              <div
                                className={`d-flex table-filter align-items-center ml-2 cursor-pointer
                                                          'active-filter'}`}
                                onClick={() =>
                                  handleSort(type, isAsc, key, index)
                                }
                              >
                                <img src={sortIcon} />
                              </div>
                            ) : (
                              ""
                            )} 
                              {includeInfoIcon && <img className="pl-2" src={InfoIcon}/>}
                          </div>
                          <div className="header-description">{description}</div>
                        </th>
                        </OverlayTrigger>
                      :
                      (includeInfoIcon && label === "User Permission")? 
                      <div className="userpermission">    
                      <div className="userpermission-info">
                      <div className="userpermission-main">
                          {
                            userPermissionList.map((item)=>{
                              return(<div className="userpermission-content">
                                <div className="d-flex justify-content-start">
                                 <div className={`frame-2939 ${item.userType.value}-all`}>
                                  <div className={item.userType.value}>
                                  <img src={item.userType.icon}/>
                                    </div></div><span 
                                 className="usertype-label"
                                 >{item.userType.label}</span> 
                                 </div>
                                 <div>
                                 {
                                  item.permissionList.map((permissionType,index)=>{
                                    return (
                                    <div className="userpermission-row">
                                      
                               <div className={`index-box`}>{index+1} </div><span className="usertype-label">{permissionType.label}</span>
                             </div>)
                                  })
                                 } 
                                 </div>
                                 {/* <div class="line-61"></div> */}
                              </div>)
                            })
                          }
                        </div>
                      </div>
                        <th className={`${className} position-relative`} key={index}> 
                        <div
                          className={`d-flex h3 align-items-center justify-content-start line-height-24 text-center fs-15 fw-500 ${
                            sortKey && "cursor-pointer"
                          } ${divClass}`}
                        >
                          {label}
                          {element && element()}
                          {isSort ? (
                            <div
                              className={`d-flex table-filter align-items-center ml-2 cursor-pointer
                                                        'active-filter'}`}
                              onClick={() =>
                                handleSort(type, isAsc, key, index)
                              }
                            >
                              <img src={sortIcon} />
                            </div>
                          ) : (
                            ""
                          )} 
                            {includeInfoIcon && <img className="pl-2" src={InfoIcon}/>}
                        </div>
                        <div className="header-description">{description}</div>
                      </th>
                      </div>
                    :
                      <th className={className} key={index}> 
                        <div
                          className={`d-flex h3 align-items-center justify-content-start line-height-24 text-center fs-15 fw-500 ${
                            sortKey && "cursor-pointer"
                          } ${divClass}`}
                        >
                          {label}
                          {element && element()}
                          {isSort ? (
                            <div
                              className={`d-flex table-filter align-items-center ml-2 cursor-pointer
                                                        'active-filter'}`}
                              onClick={() =>
                                handleSort(type, isAsc, key, index)
                              }
                            >
                              <img src={sortIcon} /> 
                            </div>
                          ) : (
                            ""
                          )} 
                            {includeInfoIcon && <img className="pl-2" src={InfoIcon}/>}
                        </div>
                        <div className="header-description">{description}</div>
                      </th>}
                      </>
                    );
                  }
                )}
              </tr>
            </thead>
            <tbody>{children}</tbody>
          </table>
          {isEmpty ? this.getPlaceHolder() : ""}
        </div>
        {/* {pageMeta.buyerCount != 0 && */}
        {/* // } */}
        {paginationInDown && 
        <div className={`${stickyPagination ? 'sticky-pagination' : 'div-pagination'} `}>
        <Pagination
          activePage={Number(this.props.page)}
          itemsCountPerPage={
            !!paginationConfig ? paginationConfig.pageCount : 0
          }
          totalItemsCount={
            !!paginationConfig ? paginationConfig.TotalCount : 0
          }
          pageRangeDisplayed={
            !!paginationConfig ? paginationConfig.btnDisplay : 0
          }
          itemClass="page-item"
          linkClass="page-link"
          innerClass=" custom-pagination pagination"
          nextPageText="Next"
          prevPageText="Previous"
          hideFirstLastPages={true}
          linkClassPrev="custom-prev"
          linkClassNext="custom-next"
          disabledClass="custom-disable"
          onChange={this.handlePageChange.bind(this)}
        />
        <div className="total-entries">
          <span className="fs-13 font-Poppins fw-400">Show</span>
          {/* <span className="coount">{!!paginationConfig?paginationConfig.itemCount:0}</span> */}
          <select
            className="custom-hide-option mx-2 fs-14 font-Poppins fw-500"
            value={!!paginationConfig ? paginationConfig.pageCount : 0}
            // disabled={disabled}
            onChange={(e) => {
              pageCountChange(e.target.value);
            }}
          >
            <option value="" disabled defaultValue>
              {placeholder}
            </option>
            {options.length !== 0 ? (
              options.map((option, index) => (
                <option
                  value={option[valueName]}
                  disabled={
                    option.value > paginationConfig.TotalCount ? true : false
                  }
                  key={index}
                >
                  {option[keyName]}
                </option>
              ))
            ) : (
              <option value="" disabled>
                No Options
              </option>
            )}
          </select>
          <span className="fs-13 font-Poppins fw-400">Entries</span>
        </div>
      </div> 

        }

      </div>
    );
  }
}

TableWrapper.propTypes = {
  placeHolder: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  isEmpty: PropTypes.bool,
  headerDetails: PropTypes.array.isRequired,
  pageMeta: PropTypes.object,
};
