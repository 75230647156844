import { addQuery } from "service/helperFunctions";
import { userManagementApi } from "../service/apiVariables";

export const getAllUsers = (query) => (dispatch, getState, { api, Toast }) => {
  return new Promise((resolve, reject) => {
    addQuery(query, userManagementApi.getAllUsers);
    api({ ...userManagementApi.getAllUsers })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

export const addCoAdmin = (body) => (dispatch, getState, { api, Toast }) => {
  return new Promise((resolve, reject) => {
    api({ ...userManagementApi.addCoAdmin, body })
      .then(({ data, message }) => {
        resolve(data);
        Toast({ type: "success", message });
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

export const updateCoAdmin = (body) => (dispatch, getState, { api, Toast }) => {
  return new Promise((resolve, reject) => {
    api({ ...userManagementApi.updateCoAdmin, body })
      .then(({ data, message }) => {
        resolve(data);
        Toast({ type: "success", message });
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

export const getUserById = (id) => (dispatch, getState, { api, Toast }) => {
  userManagementApi.getUserById.addQuery = { key: "id", payload: id };

  return new Promise((resolve, reject) => {
    api({ ...userManagementApi.getUserById })
      .then(({ data, message }) => {
        resolve(data);
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

export const archiveUser = (body) => (dispatch, getState, { api, Toast }) => {
  return new Promise((resolve, reject) => {
    api({ ...userManagementApi.archiveUser, body })
      .then(({ data, message }) => {
        resolve(data);
        Toast({ type: "success", message });
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

export const enablePermission = (body) => (
  dispatch,
  getState,
  { api, Toast }
) => {
  return new Promise((resolve, reject) => {
    api({ ...userManagementApi.enablePermission, body })
      .then(({ data, message }) => {
        resolve(data);
        Toast({ type: "success", message });
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

export const activeStatus = (body) => (dispatch, getState, { api, Toast }) => {
  return new Promise((resolve, reject) => {
    api({ ...userManagementApi.activeStatus, body })
      .then(({ data, message }) => {
        resolve(data);
        Toast({ type: "success", message });
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};


export const updatetUserdetail = (body) => (dispatch, getState, { api, Toast }) => {
  return new Promise((resolve, reject) => {
    api({ ...userManagementApi.updateUser, body })
      .then(({ data, message }) => {
        resolve(data);
        Toast({ type: "success", message });
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

export const searchUsers = (query) => (dispatch, getState, { api, Toast }) => {
  return new Promise((resolve, reject) => {
    addQuery(query, userManagementApi.search);
    api({ ...userManagementApi.search })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};