import React from 'react';
import "./style.scss";

export const NormalRadio = ({
    className = "custom-radio",
    label = "",
    value = "",
    name = "",
    onChange,
    checked,
    disable = false,
}) => {

    return (
        // <div className="custom-radio">

        // </div>
        <div className={`custom-radio ${className}`}>
            <input
                type="radio"
                name={name}
                value={value}
                checked={checked}
                disabled={disable}
                onChange={({ target: { name, checked: Checked } }) => {
                    onChange && onChange({ target: { name, value: Checked } })
                }}
            />
            {label ? <label className="label-txt fs-16 pl-2 ml-1">{label}</label> : ""}
        </div>
    )

}

