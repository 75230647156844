import React, { useEffect, useState } from "react";
import { logout } from "../../../service/utilities";
import "./navbar.scss";
import { pageNavigationByName, store } from "service/helpers";
import {
  getNotificationsCout,
  getNotifications,
} from "../../../action/notification";
import { getUserById } from "action/UserManagementAct";
import { bindActionCreators } from "redux";
import { connect, useSelector } from "react-redux";
import { useDispatch } from "react-redux";

import nav_bell from "../../../assets/svg/mdi_notifications.svg";
import nav_bell_grey from '../../../assets/svg/notificationGrey.svg';
import nav_user from "../../../assets/svg/user.svg";
import logoutIcon from "../../../assets/svg/logout.svg";
import menu from "../../../assets/svg/menu.svg";
import logo from "../../../assets/svg/Staff IQ.svg";
import DisplayImage from "../DisplayImage";

function Navbar(props) {
  const dispatch = useDispatch();
  const [userInfo, setuserInfo] = useState(null);
  const [count, setCount] = useState(null);
  const [refresh, setRefresh] = useState(false);
  const [loading, setLoading] = useState(false);
  // useEffect(() => {

  //   getCount();
  //   // const timer = setTimeout(() => getNotifyCount(), 2000);
  // }, []);

  useEffect(() => {
    if ("userData" in localStorage) {
      let userData = JSON.parse(localStorage.getItem("userData"));
      let id = userData.id ? userData.id : userData.userId;
      setLoading(true);
      dispatch(getUserById(id))
        .then((res) => {
          if (res) {
            setuserInfo(res);
            setLoading(false);
          }
        })
        .catch(() => {
          setLoading(false);
        });
    }

 
  }, [ store.getState().navbar.isNavbarItemUpdated]);

  let notificationCount = useSelector((state)=> state.webSocketReducer.notificationCount)
  useEffect(()=>{
    setCount(notificationCount)
   },[notificationCount]);

  useEffect(() => {
        getNotifyCount();
  }, []);

  const getNotifyCount = async () => {
    setLoading(true);
    await props
      .getNotificationsCout({})
      .then((data) => {
        setCount(data.count);
        setLoading(false);
      })
      .catch((e) => setLoading(false));
  };

  const handleNotification = async () => {
    setRefresh(!refresh);
    setCount(0);
    // await props.getNotifications();
    // pageNavigationByName({ name: "notificationPanel" });
    props.handleSideBar();
    // const newTab = window.open('notificationPanel', '_blank');
    //             if (newTab != null) {
    //               newTab.focus();
    //             }
  };
  let { profilePic, name } = userInfo ? userInfo : "";

  return (
    <>
      <div className="navbar fixed-top p-3">
        <div className="d-flex justify-content-end w-100 align-items-center">
          {/* <div className='search-field'>
                     <input
                        className='form-control '
                        type='text'
                        placeholder='search...'
                     />
                  </div> */}
          <div className="mr-4 pr-3 nav-logo">
            <img src={menu} onClick={props.handleNav} className="mb-3 mr-5 cursor-pointer" />
            <img src={logo} className="mb-3 " />
          </div>
          <div className="mr-4 pr-3 position-relative">
            <img
              src={`${props.sideBarClickStatus?nav_bell:nav_bell_grey}`}
              className="mb-3 cursor-pointer"
              onClick={handleNotification}
            />
            <span className="badge my-badge">{!!count ? count : ""}</span>
          </div>
          <div className="mr-2 d-flex align-items-center pr-3">
            {/* <img
              src={profilePic ? profilePic.split("|")[1] : nav_user}
              className="profile-nav rounded-circle mb-3"
            /> */}
            {profilePic ? profilePic.includes('data:image/')  ?
              <img
                src={profilePic ? profilePic.split("|")[1] : nav_user}
                className="profile-nav rounded-circle mb-3"
              />
          :
            <DisplayImage navprofile={true} entity="admin" navbar={true} imageId={profilePic}/>
          : 
          <img src={nav_user} className="profile-nav rounded-circle mb-3"/>}
            <p className="mx-2">{name ? name : "User Name"}</p>
          </div>
          <div className="vert-line mr-3"></div>

          <div
            className="d-flex align-items-center mr-4 font-Montserrat"
            style={{ cursor: "pointer" }}
            onClick={()=>logout(true)}
          >
            <img className="mb-3" src={logoutIcon} alt="user" />
            <p className=" mx-2 fw-600">Logout</p>
          </div>
        </div>
      </div>
    </>
  );
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getNotificationsCout,
      getNotifications,
    },
    dispatch
  );
};

export default connect(mapDispatchToProps, mapDispatchToProps)(Navbar);
