
const isNavbarItemUpdated = 'isNavbarItemUpdated'

const initialState= {
    isNavbarItemUpdated: false,
}

export function changeIsNavbarItemUpdated(){
    return {
        type:isNavbarItemUpdated,
        info:'Change when Navbar Item will be updated.'
    }
}

export default (state = initialState, action)=>{
    switch(action.type){
        case isNavbarItemUpdated:
            return {
                ...state,
                isNavbarItemUpdated : !state.isNavbarItemUpdated
            }
        default : return state
    }
}