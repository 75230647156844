import React, { Component } from "react";
import DatePicker from "react-datepicker";
import "./date.scss";
import iconImg from 'assets/svg/calender.svg';
import grayIcon from 'assets/svg/Vectorgray.svg';
// import "react-datepicker/dist/react-datepicker.css";

export class NormalDate extends Component {

  openDatepicker = () => this._calendar.setOpen(true);
  
  render() {
    let {
      className = "form-control w-100",
      placeholder = "DD/MM/YY",
      onChange,
      value = "",
      name,
      disabled = false,
      isReadOnly = false,
      showYearPicker = false,
      showFullMonthYearPickerStatus = false,
      showMonthYearPickerStatus = false,
      showYearDropdown = false,
      showMonthDropdown = false,
      maxdate = false,
      mindate = false,
      timeInput = false,
      excludedates,
      includeDates,
      label = "",
      isIcon = false,
      dateFormat = ""
    } = this.props;

    
    return (
      <div className="normal-date-input">
        {label !== "" ? (
          <div className="fs-17 text-black font-JosefinSans pb-2 mb-1 line-height-20">
            {label}
          </div>
        ) : (
            ""
          )}
        {!isReadOnly ? (
          <div className="position-relative">
            <DatePicker
              ref={(c) => this._calendar = c}
              calendarIcon={true}
              timeInputLabel={timeInput && "Time:"}
              showTimeInput={timeInput}
              className={className}
              disabled={disabled}
              excludeDates={excludedates?[new Date()]:''}
              placeholderText={placeholder}
              selected={value}
              includeDates={includeDates?includeDates:''}
              showYearPicker={showYearPicker}
              showMonthYearPicker={showMonthYearPickerStatus}
              showFullMonthYearPicker={showFullMonthYearPickerStatus}
              showMonthDropdown={showMonthDropdown}
              showYearDropdown={showYearDropdown}
              dateFormat={dateFormat ? dateFormat : "MM/dd/yyyy"}
              maxDate={maxdate ? new Date() : false}
              // maxDate={new Date()}
              minDate={mindate?mindate:'' }
              autoComplete="off"
              onChange={(date) => {
                let body = {};

                body = {
                  target: {
                    name: name,
                    value: date,
                  },
                };

                onChange(body);
              }}
              name={name}
            />
            {
              isIcon ?
                <img className={`${className === 'form-control mini-date' ? 'small-icoon-calender' : 'calender-icon'}`}
                  src={className === "form-control mini-date" ? grayIcon : iconImg} onClick={this.openDatepicker} />
                : ''
            }
          </div>
        ) : (
            <p>{value}</p>
          )}
      </div>
    );
  }
}
