import React, { Component } from 'react'
import "./select.scss"
export class NormalStatusSelect extends Component {
    render() {

        let {
            className = "form-control select-form-control w-100",
            options = [],
            keyName = 'label',
            valueName = "value",
            handleChange,
            value = '',
            name = '',
            placeholder = "Select",
            disabled = false,
            arrow = false,
            isReadOnly = false,
            label = ''
        } = this.props
        return (
            <>
                {!isReadOnly ?

                    <div className={`total-entries `}>
                        <select
                            className={`${className} custom-hide-option`}
                            value={value}
                            disabled={disabled}
                            onChange={e => {
                                let body = {
                                    target: {
                                        name: e.target.name,
                                        value: e.target.value // === "" ? e.target.value : Number(e.target.value)
                                    }
                                }
                                handleChange(body)
                            }}
                            name={name}
                        >
                            <option value='' disabled defaultValue>{placeholder}</option>
                            {options.length !== 0 ?
                                options.map((option, index) =>
                                    <option className="p-3 rounded text-black" value={option[valueName]} key={index}>{option[keyName]}</option>
                                ) : <option className="p-3 rounded text-black" value='' disabled>No Options</option>}


                        </select>
                      
                    </div>
                    :
                    <p>{value}</p>
                }
            </>
        )
    }
}

