const { UPDATE_CONNECTION_STATUS, UPDATE_NOTIFICATION_COUNT, UPDATE_PERMISSIONS  } = require("service/actionType");



const initialState = {
    isConnected:false,
    notificationCount:false,
    permissions:null,
  };



  export default  (state = initialState, action) => {
    switch (action.type) {
      case UPDATE_CONNECTION_STATUS:
        return {
          ...state,
          isConnected: action.payload,
        };
        case UPDATE_NOTIFICATION_COUNT:
          return {
            ...state,
            notificationCount: action.payload,
          };
          case UPDATE_PERMISSIONS:
            return {
              ...state,
              permissions: action.payload,
            };
      default:
        return state;
    }
  };
  