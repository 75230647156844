import React, { Component } from "react";

export class NormalButton extends Component {
  render() {
    const {
      className = "",
      label = "",
      leftIcon = "",
      onClick,
      id,
      disabled = false,
      outline = false,
      mainbg = false,
      link = false,
      normal = false,
      danger = false,
      rightIcon = "",
      loader = false
    } = this.props;

    return (
      <div className="">
        <button
          id={id}
          className={`btn cursor-pointer ${outline ? "outline-btn" : ""} ${mainbg ? "mainbg-btn" : ""
            } ${normal ? "normal-btn" : ""} ${danger ? "danger-btn" : ""} ${link ? "delete-btn" : ""} ${className}`}
          onClick={onClick}
          disabled={disabled || loader}
        >
          {leftIcon !== "" ? (<img src={leftIcon} />) : ('')}
          {label}
          {rightIcon !== "" ? (<img src={rightIcon} />) : ('')}
          {rightIcon !== "" ? (
            <span className={`btn-right-icon ${rightIcon}`}></span>
          ) : (
              ""
            )}
          {loader ?
            (
              <div className="spinner-border ml-2">
                <span className="sr-only">Loading...</span>
              </div>
            )
            : (
              ""
            )}
        </button>
      </div>
    );
  }
}
