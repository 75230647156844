import React, { Component } from 'react'
import "./select.scss"
export class CustomNormalSelect extends Component {
    render() {

        let {
            className = "form-control select-form-control w-100",
            options = [],
            keyName = 'label',
            valueName = "value",
            handleChange,
            value = '',
            name = '',
            placeholder = "Select",
            disabled = false,
            arrow = false,
            isReadOnly = false,
            label = ''
        } = this.props
        return (
            <>
                {!isReadOnly ?

                    <div className={`${arrow ? 'select-section' : 'Select'}`}>
                        {label !== '' ?
                            <div className="fs-16 text-black font-JosefinSans pb-2 mb-1 line-height-20">
                                {label}
                            </div>
                            : ''}
                        <select
                            className={className}
                            value={value}
                            disabled={disabled}
                            onChange={e => {
                                let body = {
                                    target: {
                                        name: e.target.name,
                                        value: e.target.value // === "" ? e.target.value : Number(e.target.value)
                                    }
                                }
                                handleChange(body)
                            }}
                            name={name}
                        >
                            <option value='' disabled defaultValue>{placeholder}</option>
                            {options.length !== 0 ?
                                options.map((option, index) =>
                                    <option className="p-3 rounded" value={option[valueName]} key={index}>{option[keyName]}</option>
                                ) : <option className="p-3 rounded" value='' disabled>No Options</option>}


                        </select>
                        {arrow &&
                            <span className="icon-down down-icon"></span>
                        }
                    </div>
                    :
                    <p>{value}</p>
                }
                    
            </>
        )
    }
}

