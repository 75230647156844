import axios from "axios";

import { history, store } from "../service/helpers";
import { logOut } from "action/AuthAct";

export const axiosInstance = axios.create({
  headers: {
    'Content-Type': 'application/json',
  },
});

export const logout = async(value) => {
  if(value){
    let auditId = localStorage.getItem('auditId')
    await store.dispatch(logOut({auditId:auditId ? auditId : null}))
    .then(()=>{ 
      if(localStorage.getItem('checked')){
        const propertiesToKeep = ['email','password','checked'] 
        for (let i=0;i<localStorage.length ;i++){
          const key = localStorage.key(i)
          if(!propertiesToKeep.includes(key))
          localStorage.removeItem(key)
        }
      }
      else {
      localStorage.clear();}
    history.push("/");
    window.location.reload(true);
    })
    .catch((err)=>{
     logout()
    })
    return;
  }

  localStorage.clear();
  history.push("/");
  window.location.reload(true);
  
};

 