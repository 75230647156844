import React, { Component } from 'react';
import "assets/scss/layouts/Auth.scss";
import logo from '../assets/images/Staff IQ.png'
import { Link } from 'react-router-dom';
export class AuthLayout extends Component {
  // constructor() {
  //     super();
  // }

  render() {
    let { children } = this.props;

    return (
      <>
        <div className="row">
          <div className="col-md-5 auth-layout justify-content-center align-items-center">
            <img src={logo} alt="Logo"  />
            <h5 className="text" >Manage On-Site WorkForce Smartly...</h5>
          </div>
          {/* <div className="col-md-6 d-flex align-items-center"> */}
          <div className="col-md-7 ">
            {/* <div className="auth-panel"> */}
            {this.props.children}
            {/* </div> */}
            {/* <div className="text-center login-footer-bottom ">
              <a href="http://180.151.69.138:2238/terms-and-conditions">
                <p className="text-black">
                  By continuing to use StaffIQ you agree to our<br />
                  <span className="font-weight-bold">Terms of use and Privacy policy</span>
                </p>
              </a>
            </div> */}
          </div>
        </div>
        <style jsx>

          {`
                        html {
                          overflow-x: hidden;
                         }
                         
                        `}
        </style>
      </>
    );
  }
}
